import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createDesignations } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, message, Select } from 'antd';

import React from 'react';
import { useDispatch } from 'react-redux';
const { Option } = Select;

const AddModal = ({
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(createDesignations(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const specificity = [
    {
      value: 'project_specific',
      name: 'Project'
    },
    {
      value: 'bu_specific',
      name: 'BU'
    },
    {
      value: 'sbu_specific',
      name: 'SBU'
    },
    {
      value: 'sbg_specific',
      name: 'SBG'
    },
    {
      value: 'corporate',
      name: 'Corporate'
    }
  ];
  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add Designation"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'Designation'}
              name="designation"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter designation'
                }
              ]}
            >
              <Input placeholder="Enter Designation" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Designation Label'}
              name="designation_label"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter label'
                }
              ]}
            >
              <Input placeholder="Enter Designation Label" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Specificity'}
              name="specificity"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select Specificity'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select Specificity"
              >
                {specificity?.map((item, idx) => (
                  <Option key={idx} value={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
