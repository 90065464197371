import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip
} from 'antd';
import {
  CaretDownFilled,
  CloudDownloadOutlined,
  EditOutlined,
  RightOutlined
} from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import FormItem from '@/common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllbus,
  getAllDesignationMappingsList,
  getAllDesignationsList,
  getAllProjects,
  getAllSbgs,
  getAllSbus,
  getAllSpecificityData,
  getDesignationMappingById,
  getFilteredResults
} from '../../redux/slice';
import EditModal from './EditModal';
import { getAllApprovers } from '@/features/initiate-envelope/redux/slice';
import AddModal from './AddModal';
import LocalStorage from 'utilities/localStorage';

const DesignationsNew = () => {
  const {
    projectsLoading,
    byIdLoading,
    all_designations,
    designationMappingById,
    all_BUs,
    all_Projects,
    all_SBUs,
    all_SBGs
  } = useSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [specificityData, setSpecificityData] = useState('bu'); // [1
  const [page, setPage] = useState(1);
  const { allApprovers } = useSelector((state) => state.approvers);
  const { filteredResults, filteredDataLoading, filteredPagination } =
    useSelector((state) => state.settings);
  const [search, setSearch] = useState(''); // [2
  const [sbuSearch, setSbuSearch] = useState(''); // [3
  const [SBUSearch, setSBUSearch] = useState(''); // [4
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // [5
  const [id, setId] = useState(null);
  const [buId, setBuId] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [sbuId, setSbuId] = useState(null);
  const [sbgId, setSbgId] = useState(null);
  const [designName, setDesignName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [sbuName, setSbuName] = useState('');
  const [SBUName, setSBUName] = useState('');
  const [sbgName, setSbgName] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [projectSearch, setProjectsSearch] = useState('');
  const [designSearch, setDesignSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // [6
  const [designationId, setDesignationId] = useState();
  const [downloadModal, setDownloadModal] = useState(false);
  const [typeOfSpecificity, setTypeOfSpecificity] = useState('');
  const showModal = (
    buId,
    designId,
    specificityData,
    buName,
    projectId,
    sbuId,
    sbgId,
    projectName,
    sbuName,
    sbgName
  ) => {
    console.log('NewID', buId);
    setIsModalOpen(true);
    setDesignName('');
    setBuId(buId);
    setDesignationId(designId);
    setTypeOfSpecificity(
      specificityData === 'bu'
        ? 'bu_specific'
        : specificityData === 'project'
        ? 'project_specific'
        : specificityData === 'sbu'
        ? 'sbu_specific'
        : specificityData === 'sbg'
        ? 'sbg_specific'
        : 'designation_specific'
    );
    setProjectId(projectId);
    setSbgId(sbgId);
    console.log(sbuName);
    setSbuId(sbuId);
    setSBUName(sbuName);
    setSbgName(sbgName);
    setSbuName(buName);
    setProjectName(projectName);
    setUserSearch('');
  };

  const onExportClick = () => {
    setDownloadModal(true);
    const user = LocalStorage.getItem('NFA_user');
    const authToken = user?.token;
    fetch(
      `${process.env.REACT_APP_API_URL}/envelope/dsg_map_export?type=${specificityData}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${specificityData}_reports.csv`);
        document.body.appendChild(link);
        link.click();
        setDownloadModal(false);
        link.parentNode.removeChild(link);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setDesignName('');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDesignName('');
  };

  const showEditModal = (id) => {
    setDesignationId(id);
    dispatch(getDesignationMappingById(id));
    handleGetAllBus();
    handleGetAllSBUs();
    handleGetAllSbgs();
    setIsEditModalOpen(true);
  };

  const handleGetAllBus = () => {
    let payload = sbuSearch !== '' ? { search: sbuSearch } : {};
    dispatch(getAllbus(payload));
  };

  const handleGetAllSBUs = () => {
    let payload = SBUSearch !== '' ? { search: SBUSearch } : {};
    dispatch(getAllSbus(payload));
  };

  const handleGetAllSbgs = () => {
    dispatch(getAllSbgs());
  };

  const handleEditOk = () => {
    setSbuSearch('');
    setProjectsSearch('');
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setSbuSearch('');
    setProjectsSearch('');
    setIsEditModalOpen(false);
  };
  const handleGetAllApprovers = () => {
    let payload = userSearch !== '' ? { search: userSearch } : {};
    dispatch(getAllApprovers(payload));
  };

  const handleGetAllDesignationsList = () => {
    let payload = { search: designSearch };
    dispatch(getAllDesignationsList(payload));
  };

  const handleGetAllDesignations = () => {
    let payload =
      search !== '' ? { search: search } : { search: search, page: page };
    dispatch(getAllDesignationMappingsList(payload));
  };

  const handleGetAllProjects = () => {
    let payload = projectSearch !== '' ? { search: projectSearch } : {};
    dispatch(getAllProjects(payload));
  };

  useEffect(() => {
    handleGetAllApprovers();
  }, [userSearch]);

  useEffect(() => {
    handleGetAllDesignationsList();
  }, [designSearch]);

  useEffect(() => {
    handleGetAllDesignations();
  }, [search, page]);

  useEffect(() => {
    handleGetAllBus();
  }, [sbuSearch]);

  useEffect(() => {
    handleGetAllSbgs();
  }, []);

  useEffect(() => {
    handleGetAllSBUs();
  }, [SBUSearch]);

  useEffect(() => {
    handleGetAllProjects();
  }, [projectSearch]);

  const handleUserSearch = (e) => {
    setUserSearch(e);
  };

  const handleSbuSearchChange = (e) => {
    setSbuSearch(e);
    return e;
  };

  const handleSBUSearchChange = (e) => {
    setSBUSearch(e);
    return e;
  };

  const handleProjectSearchChange = (e) => {
    setProjectsSearch(e);
    return e;
  };

  const handleDesignationSearchChange = (e) => {
    setDesignSearch(e);
    return e;
  };
  const specificity = [
    {
      value: 'bu',
      name: 'BU'
    },
    {
      value: 'project',
      name: 'Project'
    },

    {
      value: 'sbu',
      name: 'SBU'
    },
    {
      value: 'sbg',
      name: 'SBG'
    },
    {
      value: 'designation',
      name: 'Corporate'
    }
  ];
  const dispatch = useDispatch();

  const onSelectSpecificity = (e) => {
    setPage(1);
    setSpecificityData(e);
    form.setFieldsValue({ specificityData: undefined });
    const payload = { type: e };
    dispatch(getAllSpecificityData(payload));
    dispatch(getFilteredResults(payload));
  };

  const onSelectDropDown = (e, child) => {
    console.log(e);
    const { children } = child;
    const payload = { type: specificityData, search: children };
    dispatch(getFilteredResults(payload));
    console.log(payload);
  };

  const handleFilteredData = () => {
    const payload = {
      type: specificityData,
      search: search,
      page: search !== '' ? 1 : page
    };
    dispatch(getFilteredResults(payload));
  };

  useEffect(() => {
    // setSpecificityData('bu');
    handleFilteredData();
  }, [page, search]);

  useEffect(() => {
    const payload = { type: specificityData };
    dispatch(getAllSpecificityData(payload));
  }, [specificityData]);

  const Specificity = {
    PROJECT: 'Project',
    BU: 'BU',
    SBU: 'SBU',
    SBG: 'SBG',
    Corporate: 'Corporate'
  };

  let LabelPlaceholder;

  switch (specificityData) {
    case 'project':
      LabelPlaceholder = Specificity.PROJECT;
      break;
    case 'bu':
      LabelPlaceholder = Specificity.BU;
      break;
    case 'sbu':
      LabelPlaceholder = Specificity.SBU;
      break;
    case 'sbg':
      LabelPlaceholder = Specificity.SBG;
      break;
    case 'designation':
      LabelPlaceholder = Specificity.Corporate;
      break;
    default:
      LabelPlaceholder = Specificity.BU; // Replace 'Default' with whatever default value you want
  }

  const allDesignations = [
    ...new Set(
      filteredResults?.flatMap((bu) =>
        bu?.designations?.map((dsg) => dsg.designation)
      )
    )
  ];

  // Create a column for each designation
  const designationColumns = allDesignations.map((dsg) => ({
    title: <div style={{ fontSize: '14px', fontWeight: '800' }}>{dsg}</div>,
    dataIndex: dsg,
    key: dsg
  }));

  // Add the BU name column to the start of the columns array
  const columns = [
    {
      ellipse: true,
      fixed: 'left',
      title: (
        <div style={{ fontSize: '14px', fontWeight: '800' }}>
          {specificityData === 'bu'
            ? 'BU Name'
            : specificityData === 'sbu'
            ? 'SBU'
            : specificityData === 'sbg'
            ? 'SBG'
            : specificityData === 'project'
            ? 'Project'
            : null}
        </div>
      ),
      dataIndex:
        specificityData === 'bu'
          ? 'bu_name'
          : specificityData === 'sbu'
          ? 'sbu_name'
          : specificityData === 'sbg'
          ? 'sbg_name'
          : specificityData === 'project'
          ? 'project'
          : null,
      key:
        specificityData === 'bu'
          ? 'bu_name'
          : specificityData === 'sbu'
          ? 'sbu_name'
          : specificityData === 'sbg'
          ? 'sbg_name'
          : specificityData === 'project'
          ? 'project'
          : null
    },
    ...designationColumns
  ];

  // Transform the data so each row contains the BU name and the user_id for each designation
  const dataSource = filteredResults.map((bu) => {
    const row = {
      bu_name: (
        <Tooltip title={bu?.bu_name}>{bu?.bu_name?.slice(0, 30)}</Tooltip>
      ),
      sbu_name: bu.sbu_name,
      sbg_name: bu.sbg_name,
      project: (
        <Tooltip title={bu?.project_name}>
          {bu?.project_name?.length > 30
            ? `${bu?.project_name?.slice(0, 30)}...`
            : `${bu?.project_name}`}
        </Tooltip>
      ),
      designation: bu?.designation
    };

    bu?.designations?.forEach((dsg) => {
      row[dsg.designation] = dsg.email ? (
        <div>
          <EditOutlined
            onClick={() => {
              showEditModal(dsg.id);
            }}
            className="cursor-pointer"
          />{' '}
          {dsg.email}
        </div>
      ) : (
        <Tooltip arrowPointAtCenter={false} title={'Add Mapping'}>
          <Tag
            className={`cursor-pointer ${styles.tag_unmapped}`}
            color="red"
            onClick={() =>
              showModal(
                bu?.bu_id,
                dsg?.dsg_id,
                specificityData,
                bu?.bu_name,
                bu?.id,
                bu?.sbu_id,
                bu?.sbg_id,
                bu?.project_name,
                bu?.sbu_name,
                bu?.sbg_name
              )
            }
          >
            Not Mapped
          </Tag>
        </Tooltip>
      );
    });

    return row;
  });

  const dataSource2 = filteredResults.map((dsg) => {
    return {
      designation: dsg.designation,
      dsg_id: dsg.dsg_id,
      user_id: dsg.user_id,
      email: dsg.email ? (
        <div>
          <EditOutlined
            onClick={() => {
              showEditModal(dsg.id);
            }}
          />{' '}
          {dsg.email}
        </div>
      ) : (
        <Tag
          className={`cursor-pointer ${styles.tag_unmapped}`}
          color="red"
          onClick={() => showModal(null, dsg.dsg_id)}
        >
          Not Mapped
        </Tag>
      ) // Use email if available, otherwise use 'Not Mapped'
    };
  });

  // Define the columns for the table
  const columns2 = [
    {
      title: (
        <div style={{ fontSize: '14px', fontWeight: '800' }}>Designation</div>
      ),
      dataIndex: 'designation',
      key: 'designation'
    },
    {
      title: <div style={{ fontSize: '14px', fontWeight: '800' }}>Email</div>,
      dataIndex: 'email',
      key: 'email'
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/designations-new"
              >
                User Matrix
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
      <Form initialValues={{ specificity: 'bu' }} form={form} layout="vertical">
        <Row className="mt-2" gutter={[16, 16]}>
          <Col span={6}>
            <FormItem
              name="specificity"
              rules={[{ required: false }]}
              label="Specificity"
            >
              <Select
                value={'bu'}
                onSelect={(e) => onSelectSpecificity(e)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select specificity"
              >
                {specificity?.map((item, idx) => (
                  <Select.Option key={idx} value={item.value}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem
              name="specificityData"
              rules={[{ required: false }]}
              label={`${LabelPlaceholder}`}
            >
              <Select
                allowClear
                onClear={() => setSearch('')}
                onSelect={(e, children) => onSelectDropDown(e, children)}
                onSearch={(e) => setSearch(e)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder={`Select ${LabelPlaceholder}`}
                className="w-100"
              >
                {filteredResults?.map((item, idx) => (
                  <Select.Option key={idx} value={item.id}>
                    {item.project_name ||
                      item.bu_name ||
                      item.sbu_name ||
                      item.sbg_name ||
                      item.designation}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={6}></Col>
          <Col className="mt-4 text-right" span={6}>
            <Button
              type="primary"
              icon={<CloudDownloadOutlined />}
              onClick={onExportClick}
            >
              Export
            </Button>
          </Col>
        </Row>
      </Form>
      {specificityData !== 'designation' ? (
        <Table
          tableLayout="auto"
          pagination={{
            showSizeChanger: false,
            showQuickJumper: false,
            onChange: (pagination) => {
              setPage(pagination);
            },
            total: filteredPagination?.total_records, // total number of items
            pageSize: filteredPagination?.total_perpage, // number of items per page
            current: page // current page number
          }}
          loading={filteredDataLoading}
          scroll={{ x: 'max-content' }}
          columns={columns}
          dataSource={dataSource}
        />
      ) : (
        <Table
          pagination={{
            showSizeChanger: false,
            showQuickJumper: false,
            onChange: (pagination) => {
              setPage(pagination);
            },
            total: filteredPagination?.total_records, // total number of items
            pageSize: filteredPagination?.total_perpage, // number of items per page
            current: page // current page number
          }}
          columns={columns2}
          dataSource={dataSource2}
        />
      )}
      {isModalOpen ? (
        <AddModal
          handleFilteredData={handleFilteredData}
          projectId={projectId}
          sbuId={sbuId}
          sbgId={sbgId}
          typeOfSpecificity={typeOfSpecificity}
          designationId={designationId}
          buId={buId}
          all_SBGs={all_SBGs}
          setSbgName={setSbgName}
          setSBUName={setSBUName}
          all_SBUs={all_SBUs}
          allApprovers={allApprovers}
          handleUserSearch={handleUserSearch}
          setUserSearch={setUserSearch}
          designName={designName}
          setDesignName={setDesignName}
          all_designations={all_designations}
          handleGetAllDesignationsList={handleGetAllDesignationsList}
          projectName={projectName}
          setProjectName={setProjectName}
          sbuName={sbuName}
          setSbuName={setSbuName}
          all_Projects={all_Projects}
          handleProjectSearchChange={handleProjectSearchChange}
          all_BUs={all_BUs}
          handleDesignationSearchChange={handleDesignationSearchChange}
          handleSBUSearchChange={handleSBUSearchChange}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen && (
        <EditModal
          designationId={designationId}
          handleFilteredData={handleFilteredData}
          projectsLoading={projectsLoading}
          projectSearch={projectSearch}
          setProjectsSearch={setProjectsSearch}
          setSBUSearch={setSBUSearch}
          setSbgName={setSbgName}
          sbgName={sbgName}
          all_SBGs={all_SBGs}
          SBUName={SBUName}
          setSBUName={setSBUName}
          all_SBUs={all_SBUs}
          byIdLoading={byIdLoading}
          allApprovers={allApprovers}
          setUserSearch={setUserSearch}
          handleUserSearch={handleUserSearch}
          sbuSearch={sbuSearch}
          setSbuSearch={setSbuSearch}
          handleDesignationSearchChange={handleDesignationSearchChange}
          setDesignName={setDesignName}
          designName={designName}
          all_designations={all_designations}
          sbuName={sbuName}
          setSbuName={setSbuName}
          projectName={projectName}
          setProjectName={setProjectName}
          all_Projects={all_Projects}
          handleProjectSearchChange={handleProjectSearchChange}
          all_BUs={all_BUs}
          handleSBUSearchChange={handleSBUSearchChange}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          id={id}
          setId={setId}
          designationMappingById={designationMappingById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      )}
      {downloadModal && (
        <Modal footer={false} visible={downloadModal} closable={false}>
          <div style={{ flexDirection: 'column' }} className="df-jc-ac">
            <Spin />
            Please wait
          </div>
        </Modal>
      )}
    </CustomLayout>
  );
};

export default DesignationsNew;
