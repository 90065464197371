import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createSbg } from '@/features/settings/redux/slice';
import { Col, Form, Input, Row, Switch, message } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.less';

const EditModal = ({
  setId,
  id,
  sbgById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isActive, setIsActive] = useState(sbgById?.is_active);
  useEffect(() => {
    setId(sbgById?.id);
    setIsActive(sbgById?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      sbg_name: sbgById?.sbg_name,
      is_active: sbgById?.is_active
    });
  }, [sbgById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createSbg(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit SBG Details"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'SBG Name'}
              name="sbg_name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter SBG Name" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Active'}
              name="is_active"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <div
                className={
                  isActive
                    ? `${styles.switcher_container}`
                    : `${styles.switcher_container1}`
                }
              >
                <Switch
                  disabled={!sbgById?.is_active}
                  checked={isActive}
                  onChange={handleSwitchChange}
                />
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default EditModal;
