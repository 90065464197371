import apiClient from '../../../utilities/apiClient';

export const review_Actions = (url) => {
  return apiClient.get(`${apiClient.Urls.final_view}/${url}`, '', true);
};
export const get_otp = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.otp}/${url}`, payload, true);
};

export const update_env = (payload) => {
  const env_id = payload.get('env_id');
  const hashkey = payload.get('hashkey');
  return apiClient.patch(
    `${apiClient.Urls.final_view}/update?envelope_id=${env_id}&hashkey=${hashkey}`,
    payload,
    false,
    'file'
  );
};

export const get_rejected_approvers = (url) => {
  return apiClient.get(`${apiClient.Urls.otp}/${url}`, '', true);
};
