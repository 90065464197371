import CustomButton from '@/common/CustomButton';

import { Col, Row, Layout, Card, Image, Menu, Dropdown, Space } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import Close from '../../images/Closesvg';
// import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';

import { useNavigate } from 'react-router-dom';
import left from '../../images/left.svg';
import right from '../../images/right.svg';
import comment from '../../images/comment.svg';
import Draggable from 'react-draggable';

const menu = (
  <Menu
    items={[
      {
        key: '1',
        label: '10%'
      },
      {
        key: '2',
        label: '30%'
      },
      {
        key: '3',
        label: '40%'
      },
      {
        key: '4',
        label: '60%'
      },
      {
        key: '5',
        label: '100%'
      },
      {
        key: '6',
        label: '115%'
      }
    ]}
  />
);

const CreateDOA2 = ({
  zoomStep = 1,
  minScale = 1,
  maxScale = 10,
  defaultScale = minScale,
  defaultRotate = 0,
  current,
  setCurrent,
  itemList
}) => {
  const [scale, setScale] = useState(defaultScale);
  const [rotate, setRotate] = useState(defaultRotate);
  const [version] = useState(0);

  const zoomIn = () => {
    const newScale = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };

  const zoomOut = () => {
    const newScale = scale - zoomStep;
    setScale(newScale <= minScale ? minScale : newScale);
  };

  const updateScale = (e) => {
    const targetScale = parseFloat(e.target.value);

    if (targetScale > scale) {
      zoomIn();
    } else {
      zoomOut();
    }

    setScale(targetScale);
  };

  const rotateRight = () => setRotate(rotate === 270 ? 0 : rotate + 90);

  const rotateLeft = () => setRotate(rotate === 270 ? 0 : rotate - 90);

  const navigate = useNavigate();

  const handleOnclickPrev = () => {
    // console.log(fileList);
    // var pdffile = fileList.files[0];
    // var pdffile_url = URL.createObjectURL(fileList[0]);
    // // $('#viewer').attr('src',pdffile_url);
    // console.log(pdffile_url);
    setCurrent(current - 1);
  };

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <div className={`df-jc-ac ${styles.x_button}`}>
              <Close />
            </div>
            <Col span={16}>
              <div className={`ml-3 font-18 `}>
                {`${localStorage.getItem('doa_name')}`}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
              <div className={`ml-3 font-14`}>
                {`${localStorage.getItem('description')}`}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
              <div className={`ml-3 font-14`}>
                Project Code: {'\u00a0'}
                {`${localStorage.getItem('project_id')}`}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
            </Col>

            <Col className={`text-right `} span={7}>
              <CustomButton className={`mr-2`}>Advanced options</CustomButton>
              <CustomButton
                className={`mr-2`}
                onClick={() => handleOnclickPrev()}
              >
                {' '}
                Go back
              </CustomButton>
              <CustomButton
                onClick={() => navigate('/dashboard')}
                type="primary"
              >
                Initiate
              </CustomButton>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout className={`${styles.layout}`}>
      <Header />
      <div className={`${styles.container}`}>
        <Row>
          <Col className={` mr-4 text-left `} span={18}>
            <Card
              className={` text-center ${styles.image_viewer_card}`}
              title={
                <div className="df-jc-ac">
                  <span className="mr-4 cursor-pointer">
                    <Image onClick={rotateLeft} src={left} preview={false} />
                  </span>
                  <span className="mr-4 cursor-pointer">
                    <Image onClick={rotateRight} src={right} preview={false} />
                  </span>
                  <div className={`mr-4 ${styles.divider}`} />
                  <Dropdown overlay={menu}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        200%
                        <CaretDownFilled />
                      </Space>
                    </a>
                  </Dropdown>
                  <input
                    type="range"
                    min={minScale}
                    max={maxScale}
                    step="0.01"
                    value={scale}
                    onChange={updateScale}
                  />
                  <div className={`ml-4 mr-4 ${styles.divider}`} />
                  <span>
                    <Image src={comment} preview={false} />
                  </span>
                </div>
              }
              bordered={false}
            >
              <React.Fragment>
                <div className={`${styles.imagesBox}`}>
                  <Draggable key={version}>
                    <div>
                      <img
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg) 
                `
                        }}
                        draggable="false"
                        src="https://placekitten.com/400/400"
                      />
                    </div>
                  </Draggable>
                </div>
              </React.Fragment>
            </Card>
          </Col>

          <Col className={`ml-2 text-center`} span={5}>
            <Card className={` text-left ${styles.env_flow}`}>
              <p className="font-18">
                <b>Envelope Flow</b>
              </p>
              <div className={`${styles.container1}`}>
                {itemList.map((item, index) => (
                  <div className={`${styles.step} `} key={index}>
                    <div className={`${styles.v_stepper}`}>
                      <div className={`${styles.circle}`}>
                        <p>{index + 1}</p>
                      </div>
                      <div className={`${styles.line}`}></div>
                    </div>

                    <div className={`${styles.content}`}>
                      <b className="font-14">{item.value}</b>
                      <div className={`${styles.small_text} font-12`}>
                        Needs to sign
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
        </Row>
        <br />
      </div>
    </Layout>
  );
};

export default CreateDOA2;
