import { Avatar, Dropdown, Menu } from 'antd';
import { CaretDownOutlined, LogoutOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';

// const { Text } = Typography;

const UserMenu = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user = LocalStorage.getItem('NFA_user');

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log(url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
  };

  const items = [
    {
      label: <div onClick={() => signOutClickHandler()}>Logout</div>,
      key: '1',
      icon: <LogoutOutlined />
    }
  ];
  const menu = <Menu items={items}></Menu>;

  return (
    <Dropdown className={`${styles.icon_color}`} overlay={menu}>
      <div className={`df-jb-ac ${styles.user_menu_container}`}>
        <Avatar className={styles.user_menu_avatar}>
          {user?.user_details?.fullname?.[0]}
        </Avatar>
        <CaretDownOutlined />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
