import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createDesignations } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, message, Select, Switch, Skeleton } from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const { Option } = Select;

const EditModal = ({
  byIdLoading,
  setId,
  id,
  designationById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllDesignations
}) => {
  const [isActive, setIsActive] = useState(designationById?.is_active);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    console.log('HH', designationById);
    setId(designationById?.id);
    setIsActive(designationById?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      is_active: designationById?.is_active,
      designation: designationById?.designation,
      designation_label: designationById?.designation_label,
      specificity: designationById?.specificity
    });
  }, [designationById, form]);

  const specificity = [
    {
      value: 'project_specific',
      name: 'Project'
    },
    {
      value: 'bu_specific',
      name: 'BU'
    },
    {
      value: 'sbu_specific',
      name: 'SBU'
    },
    {
      value: 'sbg_specific',
      name: 'SBG'
    },
    {
      value: 'corporate',
      name: 'Corporate'
    }
  ];

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createDesignations(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Designation Details"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label={'Designation'}
                name="designation"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter designation'
                  }
                ]}
              >
                <Input placeholder="Enter Designation" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Designation Label'}
                name="designation_label"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter label'
                  }
                ]}
              >
                <Input placeholder="Enter Designation Label" />
              </FormItem>
            </Col>

            <Col span={24}>
              <FormItem
                label={'Specificity'}
                name="specificity"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select specificity'
                  }
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select Sepecificity"
                >
                  {specificity?.map((item, idx) => (
                    <Option key={idx} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Status'}
                name="is_active"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? styles.switcher_container
                      : styles.switcher_container1
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
