import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changepassword } from './redux/slice';
import ChangePasswordPage from './components/ChangePasswordPage';

const ChangePassword = () => {
  const { loading } = useSelector((state) => state.forgotPassword);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(changepassword(payload));
  };

  return <ChangePasswordPage loading={loading} onSubmit={onSubmit} />;
};

export default ChangePassword;
