import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createBu } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, message } from 'antd';

import React from 'react';
import { useDispatch } from 'react-redux';
const { Option } = Select;

const AddModal = ({
  handleSbuSearchChange,
  all_SBUs,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    dispatch(createBu(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const activeSBUs = all_SBUs?.filter((item) => item?.is_active);
  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add BU"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'SBU'}
              name="sbu_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbuSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBU"
              >
                {activeSBUs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbu_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              label={'BU Name'}
              name="name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter BU Name" />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
