import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Card,
  Tooltip,
  Alert,
  Spin,
  Tag,
  Steps,
  Collapse
} from 'antd';
import CustomButton from '@/common/CustomButton';
import styles from './index.module.less';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import ForwardModal from '../ForwardModal';
import RejectModal from '../RejectModal';
import HoldModal from '../HoldModal';
import ApproveModal from '../ApproveModal';
import LocalStorage from 'utilities/localStorage';
// import MessageIcon from '../../images/shape.svg';
import { history } from '@/app/history';
import {
  WarningOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  StopOutlined,
  MessageOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  MinusOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { ProgressBar, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CancelModal from '../CancelModal';
const { Panel } = Collapse;
const viewFile = ({
  allFileLoading,
  fileList,
  setFileList,
  all_file_data,
  loading,
  onFinish,
  isApproveModalVisible,
  isForwardModalVisible,
  isHoldModalVisible,
  isRejectModalVisible,
  handleApproveCancel,
  handleForwardCancel,
  handleHoldCancel,
  handleRejectCancel,
  modalVisible,
  setModalVisible,
  sentotp,
  otp,
  allApprovers,
  handleSetReject,
  rejected_approvers,
  setEditMode,
  editMode,
  onFinishCancel
}) => {
  const user = LocalStorage.getItem('NFA_user');
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const handleEditEnv = (fileData) => {
    localStorage.setItem('envelope_name', fileData.title);
    localStorage.setItem('description', fileData.description);
    setEditMode(true);
    history.push(`/initiate-envelope-1/${fileData.id}?mode=edit`);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth when the window is resized
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '20px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '20px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '20px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '20px' }} />;
      default:
        return null;
    }
  };

  const getStatusHeaderIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '10px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '10px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '10px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '10px' }} />;
      default:
        return null;
    }
  };

  const getSubStatusHeaderIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: 'green', fontSize: '10px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '10px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '10px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '10px' }} />;
      case 'Needs to review':
        return (
          <ClockCircleOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      default:
        return null;
    }
  };

  const getSubStatusIcon = (status) => {
    switch (status) {
      case status:
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      default:
        return null;
    }
  };

  const getStatusText = (item) => {
    if (item.status === 'Returned' && item.is_forward) return 'Returned';
    if (item.status === 'Accepted' && item.is_forward) return 'Reviewed';
    if (item.status === 'Accepted' && !item.is_forward) return 'Signed';
    if (item.status === 'Hold') return 'Need Clarification';
    return item.status;
  };

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col xs={24} xl={15} span={15}>
              <div className={`ml-3 sfprotext-bold font-18 mb-3 `}>
                Please review and sign the documents1
              </div>

              <div className={`ml-3 sfprotext-bold font-16`}>
                {all_file_data?.created_by}
              </div>
              <div className={`ml-3 font-14`}>{all_file_data?.user?.email}</div>
            </Col>
            {all_file_data?.status === 'Completed' ? (
              <Col className={`text-right `} xs={24} xl={9} span={9}>
                <a
                  // href={all_file_data.document_url}
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/user/fetch_file?blobName=${all_file_data?.document_url
                    ?.split('/')
                    ?.pop()}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CustomButton className={`mr-2`} type="primary">
                    {' '}
                    Download document
                  </CustomButton>
                </a>
              </Col>
            ) : null}

            {all_file_data?.status !== 'Completed' &&
            all_file_data?.status !== 'Cancelled' &&
            all_file_data?.status !== 'Rejected' &&
            all_file_data?.email === user?.user_details?.email &&
            !all_file_data?.approvers?.some(
              (approver) =>
                approver.email === user?.user_details?.email &&
                approver.is_forward
            ) ? (
              <Col className={`text-right`} xs={24} xl={9} span={9}>
                <CustomButton
                  onClick={() => setModalVisible('cancel')}
                  className={`mr-2`}
                  type="primary"
                >
                  Cancel Envelope
                </CustomButton>
              </Col>
            ) : null}

            {all_file_data.status !== 'Rejected'
              ? all_file_data?.approvers?.map((item, index) =>
                  (item.email === user.user_details.email &&
                    !item.is_forward &&
                    item.sign_in) ||
                  item.is_rejected ? (
                    <Col
                      className={`text-center mt-3 df-jb-ae`}
                      xs={24}
                      xl={9}
                      span={9}
                      key={index}
                    >
                      <CustomButton
                        className={`mr-2 mt-2`}
                        onClick={() => setModalVisible('hold')}
                      >
                        {' '}
                        Need Clarification
                      </CustomButton>
                      <CustomButton
                        className={`mr-2 mt-2`}
                        onClick={() => setModalVisible('forward')}
                      >
                        {' '}
                        Forward
                      </CustomButton>
                      <CustomButton
                        className={`mr-2 mt-2`}
                        onClick={() => handleSetReject('reject')}
                      >
                        {' '}
                        Reject
                      </CustomButton>
                      <CustomButton type="primary" onClick={sentotp}>
                        Approve
                      </CustomButton>
                    </Col>
                  ) : item?.forwarded_person_data?.some(
                      (person) =>
                        person?.email === user?.user_details.email &&
                        person?.sign_in &&
                        person?.is_forward
                    ) ? (
                    <Col
                      className={` mt-3 text-right df-jb-ae`}
                      xs={24}
                      xl={9}
                      span={9}
                      key={index}
                    >
                      <CustomButton
                        className={`mr-2`}
                        type="primary"
                        onClick={() => setModalVisible('forward-comments')}
                      >
                        {' '}
                        Review
                      </CustomButton>
                    </Col>
                  ) : (
                    ''
                  )
                )
              : ''}

            {all_file_data.is_update && all_file_data.status !== 'Rejected' ? (
              <Col className={`text-right `} span={9}>
                <CustomButton
                  className={`mr-2`}
                  type="primary"
                  onClick={() => handleEditEnv(all_file_data)}
                >
                  {' '}
                  Edit
                </CustomButton>
              </Col>
            ) : (
              ''
            )}
          </Row>
          <Row>
            <Col span={23}>
              <div
                className={`ml-3 sfprotext-medium font-14`}
                style={{ width: '100%' }}
              >
                <Tooltip placement="top" title={all_file_data?.message}>
                  {all_file_data?.message?.length > 300
                    ? all_file_data?.message.substring(0, 300) + '...'
                    : all_file_data?.message}
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout className={`${styles.layout}`}>
      {allFileLoading ? (
        <div
          style={{ flexDirection: 'column', height: '100vh' }}
          className="df-jc-ac"
        >
          <Spin />
        </div>
      ) : (
        <>
          <Header />
          <div className={`${styles.container}`}>
            <Row>
              <Col className={`mr-3 text-left mt-3`} xs={24} xl={17} span={16}>
                <Card
                  hoverable
                  className={` text-center ${styles.image_viewer_card}`}
                  title={
                    <div className="df-jc-ac sfprotext-bold">
                      Preview your document below
                    </div>
                  }
                  bordered={false}
                >
                  <div
                    style={{
                      border: '1px solid #eaeaea',
                      height: '750px',
                      width: 'auto'
                    }}
                  >
                    <Viewer
                      renderLoader={(percentages) => (
                        <div style={{ width: '240px' }}>
                          <ProgressBar progress={Math.round(percentages)} />
                        </div>
                      )}
                      defaultScale={windowWidth < 768 ? 0.4 : 1.2}
                      plugins={[defaultLayoutPluginInstance]}
                      fileUrl={`${
                        process.env.REACT_APP_API_URL
                      }/user/fetch_file?blobName=${all_file_data?.document_url
                        ?.split('/')
                        ?.pop()}`}
                    />
                  </div>
                  {all_file_data?.status === 'Cancelled' && (
                    <>
                      <Alert
                        type="warning"
                        className="mt-4 text-left"
                        message={
                          <b className="font-16 sfprotext-bold">
                            Cancelled Remarks:
                          </b>
                        }
                        description={
                          <Tooltip
                            placement="top"
                            title={all_file_data?.cancel_remarks}
                          >
                            {all_file_data?.cancel_remarks?.length > 200
                              ? all_file_data?.cancel_remarks?.substring(
                                  0,
                                  200
                                ) + '...'
                              : all_file_data?.cancel_remarks}
                          </Tooltip>
                        }
                        // type="info"
                        showIcon
                      />
                    </>
                  )}
                </Card>
                {all_file_data.approver_documents?.length !== 0 && (
                  <Card
                    hoverable
                    className={`mt-3 text-center ${styles.image_viewer_card}`}
                    bordered={false}
                  >
                    <div className={`${styles.att_bg}`}>
                      <div className={`ml-5 text-left sfprotext-bold font-18`}>
                        Approver Attachments:
                      </div>
                      <ol className="ml-5 text-left">
                        {all_file_data?.approver_documents?.map(
                          (item, index) => (
                            <li key={index}>
                              <a
                                rel="noreferrer"
                                href={`${
                                  process.env.REACT_APP_API_URL
                                }/user/fetch_file?blobName=${item?.document_url
                                  ?.split('/')
                                  ?.pop()}`}
                                target={'_blank'}
                                download
                              >
                                Attachment {index + 1}
                              </a>
                            </li>
                          )
                        )}
                      </ol>
                    </div>
                  </Card>
                )}
              </Col>

              <Col
                className={`pl-3 text-center mr-3 mt-3`}
                xs={24}
                xl={6}
                span={6}
              >
                <Card hoverable className={`text-left ${styles.env_flow}`}>
                  <p className="font-18">
                    <b>
                      Envelope Flow
                      <Tag color="#4945ff" className="ml-3">
                        {all_file_data?.envelope_type?.charAt(0).toUpperCase() +
                          all_file_data?.envelope_type?.slice(1) || '...'}
                      </Tag>
                    </b>
                  </p>
                  <div className={`${styles.container1}`}>
                    <Steps size="small" direction="vertical">
                      {all_file_data?.approvers?.map((item, index) => (
                        <Steps.Step
                          key={index}
                          title={
                            <Tooltip
                              className="font-12 bold"
                              placement="top"
                              title={item.name}
                            >
                              <b>
                                {item.name.length > 30
                                  ? item.name.substring(0, 30) + '...'
                                  : item.name}
                              </b>
                            </Tooltip>
                          }
                          description={
                            <div
                              className={`${styles.small_text} mb-2 font-10`}
                            >
                              {item.status ? (
                                <>
                                  <div style={{ marginBottom: '-10px' }}>
                                    <span
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: '400'
                                      }}
                                    >
                                      {getStatusHeaderIcon(item.status)}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: '4px'
                                      }}
                                    >
                                      {getStatusText(item)}
                                      {` on ${moment(item.updatedAt).format(
                                        'DD MMM YY, h:mm A'
                                      )}`}
                                    </span>
                                  </div>
                                  {item.comments && (
                                    <>
                                      <br />
                                      <span>
                                        <MessageOutlined
                                          style={{ fontSize: '11px' }}
                                        />
                                      </span>
                                      <span style={{ marginLeft: '4px' }}>
                                        <Tooltip
                                          placement="top"
                                          title={item.comments}
                                        >
                                          {item.comments.length > 40
                                            ? item.comments.substring(0, 40) +
                                              '...'
                                            : item.comments}
                                        </Tooltip>
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                'Needs to sign'
                              )}
                              {item?.forwarded_person_data &&
                                item?.forwarded_person_data?.length > 0 && (
                                  <Collapse
                                    expandIcon={({ isActive }) => {
                                      return !isActive ? (
                                        <PlusOutlined
                                          style={{
                                            height: '100%'
                                          }}
                                        />
                                      ) : (
                                        <MinusOutlined
                                          style={{
                                            height: '100%'
                                          }}
                                        />
                                      );
                                    }}
                                    expandIconPosition="start"
                                    accordion
                                    bordered={false}
                                    ghost
                                    className={styles.subSteps}
                                  >
                                    <Panel
                                      header={
                                        <div
                                          className="h-100  p-1"
                                          style={{
                                            height: '100%',
                                            fontSize: '10px'
                                          }}
                                        >
                                          Forwarded to
                                        </div>
                                      }
                                      key="1"
                                    >
                                      <Steps
                                        size="small"
                                        direction="vertical"
                                        className={styles.subSteps}
                                        current={
                                          item?.forwarded_person_data?.length -
                                          1
                                        }
                                      >
                                        {item.forwarded_person_data.map(
                                          (fwdItem, fwdIndex) => (
                                            <Steps.Step
                                              key={fwdIndex}
                                              title={
                                                <Tooltip
                                                  className="font-12 bold"
                                                  placement="top"
                                                  title={fwdItem.name}
                                                >
                                                  <b>
                                                    {fwdItem.name.length > 30
                                                      ? fwdItem.name.substring(
                                                          0,
                                                          30
                                                        ) + '...'
                                                      : fwdItem.name}
                                                  </b>
                                                </Tooltip>
                                              }
                                              description={
                                                <div
                                                  className={`${styles.small_text} font-10`}
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: '-10px'
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: '11px',
                                                        fontWeight: '400'
                                                      }}
                                                    >
                                                      {getSubStatusHeaderIcon(
                                                        fwdItem.status
                                                      )}
                                                    </span>
                                                    <span
                                                      style={{
                                                        marginLeft: '4px'
                                                      }}
                                                    >
                                                      {getStatusText(fwdItem)}
                                                      {` on ${moment(
                                                        fwdItem.updatedAt
                                                      ).format(
                                                        'DD MMM YY, h:mm A'
                                                      )}`}
                                                    </span>
                                                  </div>
                                                  {fwdItem.comments && (
                                                    <>
                                                      <br />
                                                      <span>
                                                        <MessageOutlined />
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: '4px'
                                                        }}
                                                      >
                                                        <Tooltip
                                                          placement="top"
                                                          title={
                                                            fwdItem.comments
                                                          }
                                                        >
                                                          {fwdItem.comments
                                                            .length > 40
                                                            ? fwdItem.comments.substring(
                                                                0,
                                                                40
                                                              ) + '...'
                                                            : fwdItem.comments}
                                                        </Tooltip>
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              }
                                              icon={getSubStatusIcon(
                                                fwdItem.status
                                              )}
                                            />
                                          )
                                        )}
                                      </Steps>
                                    </Panel>
                                  </Collapse>
                                )}
                            </div>
                          }
                          icon={getStatusIcon(item.status)}
                        />
                      ))}
                    </Steps>
                  </div>
                </Card>
              </Col>
            </Row>
            <br />
          </div>
        </>
      )}
      {modalVisible === 'approved' ? (
        <ApproveModal
          fileList={fileList}
          setFileList={setFileList}
          handleApproveCancel={handleApproveCancel}
          isApproveModalVisible={isApproveModalVisible}
          onFinish={onFinish}
          loading={loading}
          otp={otp}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      ) : null}
      {modalVisible === 'forward' ||
      modalVisible === 'forward-comments' ||
      modalVisible === 'rejected-comments' ? (
        <ForwardModal
          fileList={fileList}
          setFileList={setFileList}
          allApprovers={allApprovers}
          handleForwardCancel={handleForwardCancel}
          isForwardModalVisible={isForwardModalVisible}
          onFinish={onFinish}
          loading={loading}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          all_file_data={all_file_data}
        />
      ) : null}
      {modalVisible === 'reject' ? (
        <RejectModal
          fileList={fileList}
          setFileList={setFileList}
          all_file_data={all_file_data}
          handleRejectCancel={handleRejectCancel}
          isRejectModalVisible={isRejectModalVisible}
          onFinish={onFinish}
          loading={loading}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          rejected_approvers={rejected_approvers}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      ) : null}
      {modalVisible === 'hold' ? (
        <HoldModal
          handleHoldCancel={handleHoldCancel}
          isHoldModalVisible={isHoldModalVisible}
          onFinish={onFinish}
          loading={loading}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      ) : null}
      {modalVisible === 'cancel' ? (
        <CancelModal
          handleHoldCancel={handleHoldCancel}
          isHoldModalVisible={isHoldModalVisible}
          onFinishCancel={onFinishCancel}
          loading={loading}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
      ) : null}
    </Layout>
  );
};

export default viewFile;
