import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Card,
  Tooltip,
  Spin,
  Tag,
  Steps,
  Collapse
} from 'antd';
import CustomButton from '@/common/CustomButton';
import styles from './index.module.less';

import {
  WarningOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  StopOutlined,
  MessageOutlined,
  PlusOutlined,
  MinusOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
// import MessageIcon from '../images/shape.svg';
import { ProgressBar, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
const { Panel } = Collapse;
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FinalReviewPage = ({ all_file_data, allFileLoading }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth when the window is resized
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleWindowResize);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '20px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '20px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '20px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '20px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '20px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '20px' }} />;
      default:
        return null;
    }
  };

  const getStatusHeaderIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: '#52c41a', fontSize: '10px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '10px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '10px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '10px' }} />;
      default:
        return null;
    }
  };

  const getSubStatusHeaderIcon = (status) => {
    switch (status) {
      case 'Accepted':
        return (
          <CheckCircleOutlined style={{ color: 'green', fontSize: '10px' }} />
        );
      case 'Rejected':
        return (
          <CloseCircleOutlined style={{ color: 'red', fontSize: '10px' }} />
        );
      case 'Hold':
        return (
          <WarningOutlined style={{ color: 'orange', fontSize: '10px' }} />
        );
      case 'Forwarded':
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Returned':
        return (
          <DoubleLeftOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      case 'Cancelled':
        return <StopOutlined style={{ color: 'red', fontSize: '10px' }} />;
      case 'Needs to review':
        return (
          <ClockCircleOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      default:
        return null;
    }
  };

  const getSubStatusIcon = (status) => {
    switch (status) {
      case status:
        return (
          <DoubleRightOutlined style={{ color: 'blue', fontSize: '10px' }} />
        );
      default:
        return null;
    }
  };

  const getStatusText = (item) => {
    if (item.status === 'Returned' && item.is_forward) return 'Returned';
    if (item.status === 'Accepted' && item.is_forward) return 'Reviewed';
    if (item.status === 'Accepted' && !item.is_forward) return 'Signed';
    if (item.status === 'Hold') return 'Need Clarification';
    return item.status;
  };

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <Col span={15}>
              {all_file_data?.status === 'Completed' ? (
                <div className={`ml-3 sfprotext-bold font-18 mb-3 `}>
                  All users have signed the document
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col className={`text-right `} xs={24} xl={9} span={9}>
              <a
                href={`${
                  process.env.REACT_APP_API_URL
                }/user/fetch_file?blobName=${all_file_data?.document_url
                  ?.split('/')
                  ?.pop()}`}
                target="_blank"
                rel="noreferrer"
              >
                <CustomButton className={`mr-2`} type="primary">
                  {' '}
                  Download document
                </CustomButton>
              </a>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout className={`${styles.layout}`}>
      {allFileLoading ? (
        <div style={{ flexDirection: 'column' }} className="df-jc-ac">
          <Spin />
        </div>
      ) : (
        <>
          {' '}
          <Header />
          <div
            className={
              all_file_data?.status === 'Approved'
                ? `${styles.container}`
                : `${styles.container_top}`
            }
          >
            <Row>
              <Col className={` mr-4 text-left `} xs={24} xl={17} span={16}>
                <Card
                  hoverable
                  className={` text-center ${styles.image_viewer_card}`}
                  title={
                    <div className="df-jc-ac sfprotext-bold ">
                      Preview your document below
                    </div>
                  }
                  bordered={false}
                >
                  <div
                    style={{
                      border: '1px solid #eaeaea',
                      height: '750px',
                      width: 'auto'
                    }}
                  >
                    <Viewer
                      renderLoader={(percentages) => (
                        <div style={{ width: '240px' }}>
                          <ProgressBar progress={Math.round(percentages)} />
                        </div>
                      )}
                      defaultScale={windowWidth < 768 ? 0.4 : 1.2}
                      plugins={[defaultLayoutPluginInstance]}
                      fileUrl={`${
                        process.env.REACT_APP_API_URL
                      }/user/fetch_file?blobName= ${all_file_data?.document_url
                        ?.split('/')
                        ?.pop()}`}
                    />
                  </div>
                </Card>
              </Col>

              <Col
                className={`pl-3 text-center mr-3 mt-3`}
                xs={24}
                xl={6}
                span={6}
              >
                <Card hoverable className={`text-left ${styles.env_flow}`}>
                  <p className="font-18">
                    <b>
                      Envelope Flow
                      <Tag color="#4945ff" className="ml-3">
                        {all_file_data?.envelope_type?.charAt(0).toUpperCase() +
                          all_file_data?.envelope_type?.slice(1) || '...'}
                      </Tag>
                    </b>
                  </p>
                  <div className={`${styles.container1}`}>
                    <Steps size="small" direction="vertical">
                      {all_file_data?.approvers?.map((item, index) => (
                        <Steps.Step
                          key={index}
                          title={
                            <Tooltip
                              className="font-12 bold"
                              placement="top"
                              title={item.name}
                            >
                              <b>
                                {item.name.length > 30
                                  ? item.name.substring(0, 30) + '...'
                                  : item.name}
                              </b>
                            </Tooltip>
                          }
                          description={
                            <div
                              className={`${styles.small_text} mb-2 font-10`}
                            >
                              {item.status ? (
                                <>
                                  <div style={{ marginBottom: '-10px' }}>
                                    <span
                                      style={{
                                        fontSize: '11px',
                                        fontWeight: '400'
                                      }}
                                    >
                                      {getStatusHeaderIcon(item.status)}
                                    </span>
                                    <span
                                      style={{
                                        marginLeft: '4px'
                                      }}
                                    >
                                      {getStatusText(item)}
                                      {` on ${moment(item.updatedAt).format(
                                        'DD MMM YY, h:mm A'
                                      )}`}
                                    </span>
                                  </div>
                                  {item.comments && (
                                    <>
                                      <br />
                                      <span>
                                        <MessageOutlined
                                          style={{ fontSize: '11px' }}
                                        />
                                      </span>
                                      <span style={{ marginLeft: '4px' }}>
                                        <Tooltip
                                          placement="top"
                                          title={item.comments}
                                        >
                                          {item.comments.length > 40
                                            ? item.comments.substring(0, 40) +
                                              '...'
                                            : item.comments}
                                        </Tooltip>
                                      </span>
                                    </>
                                  )}
                                </>
                              ) : (
                                'Needs to sign'
                              )}
                              {item?.forwarded_person_data &&
                                item?.forwarded_person_data?.length > 0 && (
                                  <Collapse
                                    expandIcon={({ isActive }) => {
                                      return !isActive ? (
                                        <PlusOutlined
                                          style={{
                                            height: '100%'
                                          }}
                                        />
                                      ) : (
                                        <MinusOutlined
                                          style={{
                                            height: '100%'
                                          }}
                                        />
                                      );
                                    }}
                                    expandIconPosition="start"
                                    accordion
                                    bordered={false}
                                    ghost
                                    className={styles.subSteps}
                                  >
                                    <Panel
                                      header={
                                        <div
                                          className="h-100  p-1"
                                          style={{
                                            height: '100%',
                                            fontSize: '10px'
                                          }}
                                        >
                                          Forwarded to
                                        </div>
                                      }
                                      key="1"
                                    >
                                      <Steps
                                        size="small"
                                        direction="vertical"
                                        className={styles.subSteps}
                                        current={
                                          item?.forwarded_person_data?.length -
                                          1
                                        }
                                      >
                                        {item.forwarded_person_data.map(
                                          (fwdItem, fwdIndex) => (
                                            <Steps.Step
                                              key={fwdIndex}
                                              title={
                                                <Tooltip
                                                  className="font-12 bold"
                                                  placement="top"
                                                  title={fwdItem.name}
                                                >
                                                  <b>
                                                    {fwdItem.name.length > 30
                                                      ? fwdItem.name.substring(
                                                          0,
                                                          30
                                                        ) + '...'
                                                      : fwdItem.name}
                                                  </b>
                                                </Tooltip>
                                              }
                                              description={
                                                <div
                                                  className={`${styles.small_text} font-10`}
                                                >
                                                  <div
                                                    style={{
                                                      marginBottom: '-10px'
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: '11px',
                                                        fontWeight: '400'
                                                      }}
                                                    >
                                                      {getSubStatusHeaderIcon(
                                                        fwdItem.status
                                                      )}
                                                    </span>
                                                    <span
                                                      style={{
                                                        marginLeft: '4px'
                                                      }}
                                                    >
                                                      {getStatusText(fwdItem)}
                                                      {` on ${moment(
                                                        fwdItem.updatedAt
                                                      ).format(
                                                        'DD MMM YY, h:mm A'
                                                      )}`}
                                                    </span>
                                                  </div>
                                                  {fwdItem.comments && (
                                                    <>
                                                      <br />
                                                      <span>
                                                        <MessageOutlined />
                                                      </span>
                                                      <span
                                                        style={{
                                                          marginLeft: '4px'
                                                        }}
                                                      >
                                                        <Tooltip
                                                          placement="top"
                                                          title={
                                                            fwdItem.comments
                                                          }
                                                        >
                                                          {fwdItem.comments
                                                            .length > 40
                                                            ? fwdItem.comments.substring(
                                                                0,
                                                                40
                                                              ) + '...'
                                                            : fwdItem.comments}
                                                        </Tooltip>
                                                      </span>
                                                    </>
                                                  )}
                                                </div>
                                              }
                                              icon={getSubStatusIcon(
                                                fwdItem.status
                                              )}
                                            />
                                          )
                                        )}
                                      </Steps>
                                    </Panel>
                                  </Collapse>
                                )}
                            </div>
                          }
                          icon={getStatusIcon(item.status)}
                        />
                      ))}
                    </Steps>
                  </div>
                </Card>
              </Col>
            </Row>
            <br />
          </div>
        </>
      )}
    </Layout>
  );
};

export default FinalReviewPage;
