import React, { useState } from 'react';
import { Button, Col, Row, Space, Alert } from 'antd';
import styles from './index.module.less';
import microsoft from 'features/login/images/microsoft_login_button.png';

import right_main from '../../images/Group 2097.svg';
import rect from '../../images/Rectangle 1134.svg';
import { Message, Icon } from 'semantic-ui-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';
import tataPro from '../../images/tata_logo.svg';
import tataRight from '../../images/Tata_logo 1.svg';
import mono from '../../images/Monochrome.svg';
import mono1 from '../../images/Monochrome 2.svg';
import mono2 from '../../images/Monochrome 3.svg';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();

  const Error = {
    message: (
      <span style={{ fontWeight: 'bold', color: '#ff4d4f' }}>
        {'Launch the app from the DX store'}
      </span>
    )
  };
  const loginUserSecret = LocalStorage.getItem('NFA_user_secret') || '';
  const data = [
    {
      image: mono,
      description:
        'Send envelopes for digital signatures with standard and configurable flows and track their journey'
    },
    {
      image: mono1,
      description:
        'Review and approve business documents with digital timestamping and multi-factor authentication'
    },
    {
      image: mono2,
      description:
        'Search and retrieve final documents from cold storage with a unique envelope hash'
    }
  ];
  return (
    <>
      {!loginUserSecret.success ? (
        <Alert
          message={Error.message}
          description="For improved security, you can only launch this app from the DX store"
          type="error"
          showIcon
        />
      ) : (
        ''
      )}
      <div className={styles.left_screen}>
        <Row className={`mt-5 ml-5 mb-5`}>
          <Space size={35}>
            <img className={`${styles.left_logo}`} src={tataPro} />
          </Space>
        </Row>

        <Row className={`mt-5 ml-5 mb-4 ${styles.main_header}`}>
          Manage all document approvals in one place
        </Row>

        <Row className="ml-5">
          {data.map((item, index) => (
            <Col className={`mb-2 ${styles.row_container}`} key={index}>
              <div className="df-jc-ac mb-4">
                <img src={item.image} />
              </div>
              <div className="df-jc-ac text-center">{item.description}</div>
            </Col>
          ))}
        </Row>
        <Row>
          {/* {loginUserSecret.success ? ( */}
          <Button
            className={`ml-5 mt-3 ${styles.secondary_button}`}
            src={microsoft}
            id="microsoft-login"
            centered="true"
            alt="Microsoft"
            onClick={() => {
              // instance
              // .acquireTokenRedirect({
              //   scopes: ['User.Read'],
              //   prompt: 'select_account'
              // })
              // .then((accessTokenResponse) => {
              //   // Acquire token silent success
              //   let accessToken = accessTokenResponse.accessToken;

              //   // Call your API with token
              //   // callApi(accessToken).then((response) => {
              //   //   setApiData(response);
              //   // });
              // });
              const user_details = LocalStorage.getItem('NFA_user');
              console.log(user_details ? user_details : 'No response');
              instance
                .loginPopup({
                  scopes: ['User.Read'],
                  prompt: 'select_account'
                })
                .then((result) => {
                  console.log('step1', result);
                  return dispatch(login({ token: result.accessToken }));
                })
                .then((result) => {
                  console.log('step2', result);
                  if (result.payload.success) {
                    LocalStorage.setItem(
                      'NFA_user',
                      JSON.stringify(result.payload)
                    );
                    const user = result.payload;
                    console.log('step3', user);
                    if (user.user_details.role === 'user') {
                      location.state?.from?.pathname
                        ? history.push(`${location.state?.from?.pathname}`)
                        : history.push('/dashboard');
                    } else if (user.user_details.role === 'admin') {
                      location.state?.from?.pathname
                        ? history.push(`${location.state?.from?.pathname}`)
                        : history.push('/admin-dashboard');
                    }
                    //   history.push('/users');
                  } else {
                    console.log('$$$$$$$$$$$$$$$$$$$');
                  }
                })
                .catch((err) => {
                  console.log('noresponse', err, err);
                  isAuthenticated
                    ? instance.logoutRedirect({
                        account: LocalStorage.homeAccountId,
                        postLogoutRedirectUri: '/',
                        mainWindowRedirectUri: '/'
                      })
                    : '';
                });
            }}
          >
            <b className="sfprotext-bold"> Go to your Account</b>
          </Button>
        </Row>
      </div>
      <div className={styles.right_screen}>
        <div className={`pull-right mt-4 mr-5`}>
          <img className={`${styles.right_logo}`} src={tataRight} />
        </div>
        <div>
          <img className={styles.rect_image} src={rect} />
        </div>
        <div>
          <img className={`${styles.overlay_image}`} src={right_main} />
        </div>
      </div>

      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </>
  );
};

export default LoginPage;
