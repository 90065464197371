import { Layout } from 'antd';
import React, { useState } from 'react';
import styles from './components/CreateEnvelope1/index.module.less';

import CreateDOA1 from './components/CreateEnvelope1';
import CreateDOA2 from './components/CreateEnvelope2';

const AdminDoaEnvelope = () => {
  const [fileList, setFileList] = useState();
  const [itemList, setItemList] = useState([{ id: 1, value: '' }]);
  const [type, setType] = useState(false);
  const [envtype, setEnvtype] = useState(false);
  const [fields, setFields] = useState([{ value: null }]);
  const [project, setProject] = useState(false);

  const [current, setCurrent] = useState(0);

  return (
    <>
      <Layout className={`${styles.layout}`}>
        <>
          {current == 0 ? (
            <div className="steps-content">
              <CreateDOA1
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setProject={setProject}
                project={project}
                setCurrent={setCurrent}
                fields={fields}
                setFields={setFields}
              />
            </div>
          ) : (
            <div className="steps-content">
              <CreateDOA2
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setCurrent={setCurrent}
              />
            </div>
          )}
        </>
      </Layout>
    </>
  );
};

export default AdminDoaEnvelope;
