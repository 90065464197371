import CustomButton from '@/common/CustomButton';

import { Form, Badge, Col, Row, Checkbox, Input } from 'antd';

import React, { useEffect } from 'react';
import Close from '../../images/Closesvg';
import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';
import { Collapse } from 'antd';
import CustomCollapse from '@/common/CustomCollapse';
import {
  DownOutlined,
  HolderOutlined,
  PlusCircleFilled
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
// import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormItem from 'common/FormItem';
import Delete from '../../images/Deletesvg';

const { Panel } = Collapse;

const onCheckBoxToggle = (e) => {
  e.target.checked;
};

const CreateDOA1 = ({
  itemList,
  setItemList,
  setType,
  type,
  setEnvtype,
  envtype,
  project,
  setProject
}) => {
  const addInput = () => {
    var highestValue = 0; //keep track of highest value

    //loop through array of objects
    for (var i = 0, len = itemList.length; i < len; i++) {
      var value = Number(itemList[i]['id']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setItemList([...itemList, { id: id, value: '' }]);
  };

  const [form] = Form.useForm();

  const removeInput = (id) => {
    let itemValues = [...itemList];
    itemValues.splice(id, 1);
    setItemList(itemValues);
  };
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setItemList(updatedList);
  };

  const edit_env = (type) => {
    if (type === 'description') {
      setType(true);
    }
    if (type === 'doa_name') {
      setEnvtype(true);
    }
    if (type === 'project_id') {
      setProject(true);
    }
  };
  const handleEdit = (value, type) => {
    if (type === 'description') {
      localStorage.setItem('description', value);
    } else if (type === 'envelope') {
      localStorage.setItem('doa_name', value);
    } else if (type === 'project') {
      localStorage.setItem('project_id', value);
    }
    setType(false);
    setEnvtype(false);
    setProject(false);
  };

  const handleNext = () => {
    // setCurrent(current + 1);
  };

  useEffect(() => {
    form.setFieldsValue({
      description: localStorage.getItem('description'),
      doa_name: localStorage.getItem('doa_name'),
      project_id: localStorage.getItem('project_id')
    });
  });

  const handleChange = (event, i) => {
    const values = [...itemList];
    values[i].value = event.target.value;
    setItemList(values);
  };
  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <div className={`df-jc-ac ${styles.x_button}`}>
              <Close />
            </div>
            <Col span={15}>
              <div className={`ml-3 font-18 `}>
                {envtype ? (
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form form={form} layout="vertical">
                        <FormItem name="doa_name">
                          <Input
                            placeholder="Enter envelope name"
                            onBlur={(e) =>
                              handleEdit(e.target.value, 'envelope')
                            }
                            autoFocus
                          />
                        </FormItem>
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  `${localStorage.getItem('doa_name')}`
                )}
                {!envtype ? (
                  <span className={`ml-3`} onClick={() => edit_env('doa_name')}>
                    <Pencil />
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className={`ml-3 font-14`}>
                {type ? (
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form form={form} layout="vertical">
                        <FormItem name="description">
                          <Input
                            placeholder="Enter description"
                            onBlur={(e) =>
                              handleEdit(e.target.value, 'description')
                            }
                            autoFocus
                          />
                        </FormItem>
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  `${localStorage.getItem('description')}`
                )}
                {!type ? (
                  <span
                    className={`ml-3`}
                    onClick={() => edit_env('description')}
                  >
                    <Pencil />
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className={`ml-3 font-14 `}>
                <span>Project Code:{'\u00a0'}</span>
                {project ? (
                  <Row gutter={8}>
                    <Col span={12}>
                      <Form form={form} layout="vertical">
                        <FormItem name="project_id">
                          <Input
                            placeholder="Enter project id"
                            onBlur={(e) =>
                              handleEdit(e.target.value, 'project')
                            }
                            autoFocus
                          />
                        </FormItem>
                      </Form>
                    </Col>
                  </Row>
                ) : (
                  `${localStorage.getItem('project_id')}`
                )}
                {!project ? (
                  <span
                    className={`ml-3`}
                    onClick={() => edit_env('project_id')}
                  >
                    <Pencil />
                  </span>
                ) : (
                  ''
                )}
              </div>
            </Col>

            <Col className={`text-right `} span={8}>
              <CustomButton className={`mr-2`}>Advanced options</CustomButton>
              <CustomButton className={`mr-2`} onClick={handleNext}>
                {' '}
                {'Save & Close'}
              </CustomButton>
              <CustomButton type="primary">Next</CustomButton>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className={`ant-form ant-form-vertical  ${styles.form_width}`}
      >
        <Header />
        <div className={`${styles.container}`}>
          <CustomCollapse
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition="end"
            bordered={false}
            defaultActiveKey={['1']}
          >
            <Panel
              header={
                <Badge className={`${styles.badge}`} count={1} dot={false} />
              }
              extra="Add approvers"
              key="1"
            >
              <div>
                <Checkbox className={`font-14`} onChange={onCheckBoxToggle}>
                  Set signing order
                </Checkbox>
              </div>
              <br />
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {itemList.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                        >
                          {(provided) => (
                            <>
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                  <Col className="gutter-row" span={1}>
                                    <div
                                      className={`
                                        ${
                                          item.ind > 1
                                            ? `${styles.step} ${styles.v_stepper}${styles.circle} `
                                            : ''
                                        } mt-2`}
                                    >
                                      {index + 1}
                                      <div className={`${styles.line}`}></div>
                                    </div>
                                  </Col>
                                  <Col className="gutter-row" span={1}>
                                    <div className="mt-2">
                                      <HolderOutlined />
                                    </div>
                                  </Col>
                                  <Col className="gutter-row" span={6}>
                                    <div>
                                      <Form.Item
                                        {...item}
                                        rules={[
                                          {
                                            required: true,
                                            whitespace: true,
                                            message:
                                              "Please input passenger's name or delete this field."
                                          }
                                        ]}
                                        noStyle
                                      >
                                        <Input
                                          placeholder="Approver name"
                                          onChange={(e) =>
                                            handleChange(e, index)
                                          }
                                          value={item.value}
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col className="gutter-row" span={3}>
                                    <div className="mt-2">
                                      <b>Needs to sign</b>
                                    </div>
                                  </Col>
                                  <Col
                                    className="gutter-row"
                                    span={6}
                                    onClick={() => removeInput(index)}
                                  >
                                    <div>
                                      <Delete
                                        className={`cursor-pointer ${styles.delete_icon}`}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <br />
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <div type="button" onClick={() => addInput()}>
                  <span className={`cursor-pointer ${styles.span_add}`}>
                    <PlusCircleFilled />
                    {'\u00a0'} Add Approvers
                  </span>
                </div>
              </DragDropContext>
            </Panel>
          </CustomCollapse>
          <br />
          <CustomCollapse
            expandIcon={({ isActive }) => (
              <DownOutlined rotate={isActive ? 180 : 0} />
            )}
            expandIconPosition="end"
            bordered={false}
          >
            <Panel
              className={` ${styles.panel}`}
              header={
                <Badge className={` ${styles.badge}`} count={2} dot={false} />
              }
              extra="Add subject and message to email"
              key="1"
            >
              <div>
                <div className="mb-1">
                  <b>Add email messsage</b>
                </div>
                <TextArea
                  className={` ${styles.text_area}`}
                  rows={4}
                  placeholder={'Enter message'}
                />
              </div>
            </Panel>
          </CustomCollapse>
          <br />
        </div>
      </Form>
    </>
  );
};

export default CreateDOA1;
