import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createDesignationMappings } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Row, Select, Skeleton, message } from 'antd';
// import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import { getLoggedInUser } from 'utilities/helpers';

const EditModal = ({
  designationId,
  handleFilteredData,
  projectsLoading,
  setProjectsSearch,
  setSbuSearch,
  sbgName,
  setSbgName,
  all_SBGs,
  SBUName,
  setSBUName,
  all_SBUs,
  handleSBUSearchChange,
  setUserSearch,
  handleUserSearch,
  allApprovers,
  projectName,
  sbuName,
  setProjectName,
  all_Projects,
  handleProjectSearchChange,
  all_BUs,
  handleSbuSearchChange,
  setId,
  setSbuName,
  id,
  designationMappingById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllDesignations,
  designName,
  setDesignName,
  all_designations,
  handleDesignationSearchChange
}) => {
  // const user = getLoggedInUser();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [isActive, setIsActive] = useState(designationMappingById?.is_active);
  const [isProjectActive, setIsProjectActive] = useState(true);
  const { designationMappingByIdLoading } = useSelector(
    (state) => state.settings
  );
  const handleSelect = (e) => {
    const selectedProject = all_Projects.find((item) => item.id === e);
    setIsProjectActive(selectedProject?.is_active);
  };
  useEffect(() => {
    setDesignName({
      name: designationMappingById?.designation_master?.designation,
      specificity: designationMappingById?.designation_master?.specificity
    });
    setSbuSearch(designationMappingById?.unit?.name);
    setId(designationMappingById?.id);
    setProjectName(designationMappingById?.project_name);
    setSbuName(designationMappingById?.sbu);
    setProjectsSearch(designationMappingById?.project_name);
    // setSBUName(designationMappingById?.sbg_id);
    setSbgName(designationMappingById?.sbg_id);
    // setIsActive(designationMappingById?.is_active);
    setUserSearch(
      designationMappingById?.user?.display_name ||
        designationMappingById?.user?.first_name
    );
    console.log(
      'designationMappingById',
      designationMappingById?.user?.first_name
    );
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      is_active: designationMappingById?.is_active,
      designation_id: designationMappingById?.designation_id,
      project_id: designationMappingById?.project_id,
      unit_id: designationMappingById?.unit_id,
      user_id: designationMappingById?.user_id,
      sbg_id: designationMappingById?.sbg_id,
      sbu_id: designationMappingById?.sbu_id
    });
  }, [designationId, designationMappingById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['project_name'] = projectName;
    values['bu'] = sbuName;
    values['sbu'] = SBUName;
    values['sbg_id'] = sbgName;
    values['designation'] = designName.name;
    // values['is_active'] = isActive;

    dispatch(createDesignationMappings(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllDesignations();
        setDesignName('');
        setProjectName('');
        setSbuName('');
        setSbgName('');
        setSBUName('');
        handleFilteredData();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const onChangeDesignation = (value, option) => {
    if (option?.specificity === 'bu_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSBUName(null); // Clear SBU name state
      setSbgName(null);
      form.setFieldsValue({
        project_id: undefined,
        sbg_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'project_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      setSbgName(null);
      form.setFieldsValue({
        unit_id: undefined,
        sbu_id: undefined,
        sbg_id: undefined
      });
    } else if (option?.specificity === 'corporate') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'sbg_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'sbu_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSbgName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbg_id: undefined
      });
    }
  };

  // const handleSwitchChange = (checked) => {
  //   setIsActive(checked);
  // };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                disabled={!isProjectActive}
                type="primary"
                htmlType="submit"
              >
                Update
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Update Designation Mappings"
    >
      {designationMappingByIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label={'Designation'}
                name="designation_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select Designation'
                  }
                ]}
              >
                <Select
                  disabled
                  onChange={onChangeDesignation}
                  // Added line to save the specificity value

                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleDesignationSearchChange}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select Designation"
                >
                  {all_designations?.map((item, index) => (
                    <Select.Option
                      specificity={item?.specificity}
                      key={index}
                      value={item?.id}
                    >
                      {item.is_active === false
                        ? `${item.designation} (Deactivated)`
                        : item.designation}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            {designName.specificity === 'bu_specific' ? (
              <Col span={24}>
                <FormItem
                  label={'BU'}
                  name="unit_id"
                  // className={`${styles.astrick}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select BU'
                    }
                  ]}
                >
                  <Select
                    disabled
                    onChange={(value, option) => setSbuName(option.children)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={handleSbuSearchChange}
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select BU"
                  >
                    {all_BUs?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.is_active === false
                          ? `${item.name} (Deactivated)`
                          : item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            ) : designName.specificity === 'project_specific' ? (
              <Col span={24}>
                <FormItem
                  label={'Project'}
                  name="project_id"
                  // className={`${styles.astrick}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select project'
                    }
                  ]}
                >
                  <Select
                    disabled
                    searchValue={designationMappingById?.project_name}
                    loading={projectsLoading}
                    onChange={(value, option) =>
                      setProjectName(option.children)
                    }
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(e) => handleSelect(e)}
                    onSearch={handleProjectSearchChange}
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Project"
                  >
                    {' '}
                    {all_Projects?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.is_active === false
                          ? `${item.project} (Deactivated)`
                          : item.project}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            ) : designName.specificity === 'sbg_specific' ? (
              <Col span={24}>
                <FormItem
                  label={'SBG'}
                  name="sbg_id"
                  // className={`${styles.astrick}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select SBG'
                    }
                  ]}
                >
                  <Select
                    disabled
                    onChange={(value, option) => setSbgName(option.children)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={handleProjectSearchChange}
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select SBG"
                  >
                    {' '}
                    {all_SBGs?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.sbg_name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            ) : designName.specificity === 'sbu_specific' ? (
              <Col span={24}>
                <FormItem
                  label={'SBU'}
                  name="sbu_id"
                  // className={`${styles.astrick}`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select SBU'
                    }
                  ]}
                >
                  <Select
                    disabled
                    onChange={(value, option) => setSBUName(option.children)}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onSearch={handleSBUSearchChange}
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select SBG"
                  >
                    {' '}
                    {all_SBUs?.map((item, index) => (
                      <Select.Option key={index} value={item.id}>
                        {item.sbu_name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            ) : null}
            <Col span={24}>
              <FormItem
                label={'User'}
                name="user_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select user'
                  }
                ]}
              >
                <Select
                  onChange={(value) => setUserSearch(value)}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleUserSearch}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select User"
                >
                  {' '}
                  {allApprovers?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.is_active === false
                        ? `${item.name} (Deactivated)`
                        : item.name + ` (${item.email})`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            {/* <Col span={24}>
              <FormItem
                label={'Status'}
                name="is_active"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? styles.switcher_container
                      : styles.switcher_container1
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col> */}
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
