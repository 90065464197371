import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
// import { CaretDownFilled } from '@ant-design/icons';

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';
import styles from './index.module.less';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

function RejectModal({
  fileList,
  setFileList,
  modalVisible,
  setModalVisible,
  onFinish,
  loading
  // rejected_approvers
}) {
  const [form] = Form.useForm();

  return (
    <CustomModal
      visible={modalVisible === 'reject' ? true : false}
      className={styles.background}
      title={'Reject the document'}
      onCancel={() => {
        setModalVisible('');
        setFileList([]);
      }}
      /* width={'45%'} */
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason for rejecting and let others know..."
              rows={4}
              allowClear
            />
          </FormItem>
          <FormItem
            label=""
            name="file"
            rules={[
              {
                required: false,
                message: 'Please attach files'
              }
            ]}
          >
            <Dragger
              multiple={true}
              listType="picture"
              showUploadList={true}
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
            >
              <p className="ant-upload-drag-icon">
                {/* Add upload icon or custom content */}
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <b>Click or drag files</b> to upload
              </p>
            </Dragger>
          </FormItem>
          {/* <FormItem label="Send back to" name="reviewer">
            <Select suffixIcon={<CaretDownFilled />} style={{ width: '100%' }}>
              {rejected_approvers?.map((item, index) => (
                <Select.Option value={item?.email} key={index}>
                  {item?.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem> */}
          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => {
                setModalVisible('');
                setFileList([]);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Reject
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default RejectModal;
