import React, { useEffect, useState } from 'react';
import { Form, Space, Select, Col, Alert, Checkbox, Row } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { CaretDownFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSbgs, getAllSbus } from '@/features/settings/redux/slice';
// import { numFormating } from 'utilities/helpers';

const AddStandardModal = ({
  setSearchSubCategory,
  setCategoryId,
  onSearchSubCategory,
  form,
  onSearchCategory,
  // selectedStatus,
  // setSelectedStatus,
  handleStandardCancel,
  isStandardModalVisible,
  loading,
  // pricebrackets,
  categories,
  handleCategory,
  subcategories,
  handleSearchstandard,
  type,
  projects,
  onSearch,
  onSelectProject,
  onSelectBU,
  onSearchbu,
  bu,

  handleSubCategory
  // setCategory,
  // category
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { all_SBUs, all_SBGs } = useSelector((state) => state.settings);
  const [subSpecificity, setSubSpecificity] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  const handleGetAllSBUs = () => {
    dispatch(getAllSbus());
  };

  const handleGetAllSbgs = () => {
    dispatch(getAllSbgs());
  };
  useEffect(() => {
    handleGetAllSBUs();
    handleGetAllSbgs();
  }, []);
  // const business = [
  //   {
  //     id: 1,
  //     name: 'Urban Infrastructure'
  //   },
  //   {
  //     id: 2,
  //     name: 'Energy & Industrial'
  //   },
  //   {
  //     id: 3,
  //     name: 'Corporate'
  //   }
  // ];
  const onFinish = (values) => {
    values['type'] = type;
    values['sub_category_id'] = values['Sub-Category'];
    values['category_id'] = values['Category'];
    handleSearchstandard(values);
    localStorage.setItem(
      'apiCallValues',
      JSON.stringify({
        unit_id: values.unit_id,
        category_id: values.category_id,
        sub_category_id: values.sub_category_id,
        project_id: values.project_id || null
      })
    );
  };

  const activeProjects = projects?.filter((item) => item?.is_active);
  const [isCategoryActive, setIsCategoryActive] = useState(true);

  // Update the handleSelect function to set the isCategoryActive state
  const handleSelectCategory = (e) => {
    form.setFieldsValue({
      'Sub-Category': undefined,
      sbg_id: undefined,
      sbu_id: undefined,
      unit_id: undefined
    });
    const selectedCategory = categories.find((item) => item.id === e);
    setSubSpecificity('');
    setCategoryId(e);
    setSearchSubCategory('');
    setIsCategoryActive(selectedCategory?.is_active);

    // Rest of your handleSelect logic...
  };

  const onSelectSubCategory = (e) => {
    const selectedSubCategory = subcategories.find((item) => item.id === e);
    console.log(selectedSubCategory.sub_specificity);

    setSubSpecificity(selectedSubCategory?.sub_specificity);
  };
  console.log(subSpecificity);
  // const onSearchCategory = (searchText) => {
  //   setCategory(
  //     categories.filter((categories) =>
  //       categories.name.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );
  // };
  return (
    <CustomModal
      width={1200}
      footer={null}
      onCancel={handleStandardCancel}
      visible={isStandardModalVisible}
      title={`Initiate ${type} DoA envelope`}
      type={'standard'}
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        {type !== 'standard' && (
          <Alert
            icon={<ExclamationCircleFilled />}
            showIcon
            message={<b>Caution</b>}
            className="mb-3"
            type="warning"
            description={
              <div
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  marginLeft: '-40px'
                }}
              >
                Custom envelopes should only be utilized in the absence of a
                Standard DOA. Before proceeding with any custom DOA, ensure that
                you have thoroughly checked the Standard DOA. Bypassing the
                Standard DOA without proper justification may result in
                procedural errors and potential consequences.
              </div>
            }
          />
        )}
        {/* <FormItem
          label="Envelope Name"
          name="envelope_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem> */}
        {/* <FormItem
          label="Business"
          name="business"
          rules={[
            {
              required: false,
              message: 'Select Business'
            }
          ]}
        >
          <Select
            suffixIcon={<CaretDownFilled />}
            placeholder="Select Business"
            // showSearch
          >
            {business.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem> */}
        <FormItem
          label="Category"
          name="Category"
          rules={[
            {
              required: true,
              message: 'Select Category'
            }
          ]}
        >
          <Select
            suffixIcon={<CaretDownFilled />}
            placeholder="Select Category"
            showSearch
            onSelect={handleSelectCategory}
            onSearch={onSearchCategory}
            onChange={(e) => handleCategory(e)}
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
          >
            {categories
              .filter((item) => item.new_cat_doa)
              .map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.is_active
                      ? item.name
                      : item.name + ' - (Deactivated)'}
                  </Option>
                );
              })}
          </Select>
        </FormItem>
        <FormItem
          label="Sub-Category"
          name="Sub-Category"
          rules={[
            {
              required: true,
              message: 'Select Sub-Category'
            }
          ]}
        >
          <Select
            // dropdownStyle={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            // dropdownRender={(menu) => (
            //   <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
            //     {menu}
            //   </div>
            // )}

            suffixIcon={<CaretDownFilled />}
            placeholder="Select Sub-Category"
            onChange={(e) => handleSubCategory(e)}
            showSearch
            onSearch={onSearchSubCategory}
            onSelect={(e, option) => onSelectSubCategory(e, option)}
            filterOption={(inputValue, option) =>
              option?.props?.children
                ?.toUpperCase()
                ?.indexOf(inputValue?.toUpperCase()) !== -1
            }
          >
            {subcategories
              .filter((item) => item.new_sub_cat_doa)
              .map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </FormItem>
        {subSpecificity === 'bu_specific' ||
        subSpecificity === 'sbg_specific' ||
        subSpecificity === 'sbu_specific' ? (
          <FormItem
            label="Business Unit"
            name="unit_id"
            rules={[
              {
                required: true,
                message: 'Select Business Unit'
              }
            ]}
          >
            <Select
              showSearch
              onSearch={onSearchbu}
              placeholder="Select Business Unit"
              onSelect={onSelectBU}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {bu?.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.is_active
                      ? item.name
                      : `${item.name} - (Deactivated)`}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        ) : null}
        {subSpecificity === 'project_specific' && (
          <FormItem
            label="Projects"
            name="project_id"
            rules={[
              {
                required: true,
                message: 'Select Project'
              }
            ]}
          >
            <Select
              showSearch
              // style={{ width: 200 }}
              onSearch={onSearch}
              placeholder="Select Project"
              onSelect={onSelectProject}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {activeProjects?.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.project}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        )}
        {subSpecificity === 'sbg_specific' && (
          <Col span={24}>
            <FormItem
              label={'SBG'}
              name="sbg_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select SBG'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBG"
              >
                {' '}
                {all_SBGs?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.sbg_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}{' '}
        {subSpecificity === 'sbu_specific' && (
          <Col span={24}>
            <FormItem
              label={'SBU'}
              name="sbu_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select SBU'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBU"
              >
                {' '}
                {all_SBUs?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.sbu_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}
        {type !== 'standard' && (
          <FormItem
            label=""
            name="checkbox"
            rules={[
              {
                required: false
              }
            ]}
          >
            <Row>
              <Col span={24}>
                <Checkbox
                  className="mr-1"
                  onChange={toggleCheckbox}
                  allowClear
                />{' '}
                <span style={{ fontWeight: 'lighter' }}>
                  I have verified the applicability of the Standard DOA for this
                  envelope and would like to proceed with the creation of a
                  custom DOA envelope.
                </span>
              </Col>
            </Row>
          </FormItem>
        )}
        {/* <FormItem
          label="Description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input.TextArea rows={5} allowClear placeholder="Enter description" />
        </FormItem> */}
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleStandardCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={
                type !== 'standard'
                  ? !isCategoryActive || !isChecked
                  : !isCategoryActive
              }
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Next
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};

export default AddStandardModal;
