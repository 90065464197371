import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createApprovers } from '@/features/settings/redux/slice';
import { Col, Form, Input, Row, message } from 'antd';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const EditModal = ({
  setId,
  id,
  approverById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    setId(approverById?.id);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      approval_role: approverById?.approval_role,
      approval_role_label: approverById?.approval_role_label
    });
  }, [approverById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    dispatch(createApprovers(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Approver Details"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'Approver Role'}
              name="approval_role"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter role'
                }
              ]}
            >
              <Input placeholder="Enter Approver Role" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Approver Label'}
              name="approval_role_label"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter label'
                }
              ]}
            >
              <Input placeholder="Enter Approver Label" />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default EditModal;
