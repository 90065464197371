import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getfilebyid } from './redux/slice';
import FinalReviewPage from './components';

function FinalReview() {
  const { all_file_data, allFileLoading } = useSelector(
    (state) => state.reviewActions
  );

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    handleGetfileById();
  }, []);

  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id));
  };

  return (
    <>
      <FinalReviewPage
        allFileLoading={allFileLoading}
        all_file_data={all_file_data}
      />
    </>
  );
}

export default FinalReview;
