import React, { createElement } from 'react';
import styles from './index.module.less';
import SearchBar from '@/common/Content/searchBar';
import Header from 'common/Content/header';
import { Image, Card, Col, Menu, Row, Tooltip } from 'antd';
import emptyState from './images/empty.svg';
import CustomButton from 'common/CustomButton';
import {
  HeartFilled,
  HeartOutlined,
  DeleteOutlined,
  FileOutlined
} from '@ant-design/icons';
import UserGroup from './images/UserGroupsvg';
import moment from 'moment';

const enevelopecard = ({
  selectedStatus,
  setSelectedStatus,
  selectedDate,
  setSelectedDate,
  allFavEnvelopes,
  handleDelete,
  handlelike
}) => {
  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const datesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];
  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const dateMenu = (
    <Menu>
      {datesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedDate?.label === item.label) {
              setSelectedDate(null);
            } else {
              setSelectedDate(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  let data = allFavEnvelopes;
  return (
    <div className={styles.MRS_container}>
      <Header
        justify="space-between"
        align="middle"
        title="Favourite DoA Envelopes"
        buttons={[]}
      />
      <div>
        <SearchBar
          justify="start"
          align="middle"
          className="mt-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search'
          }}
          filters={true}
          statusMenu={statusMenu}
          datesMenu={dateMenu}
        />
      </div>

      <div>
        {data.length ? (
          <>
            <Row className="mt-4" gutter={[16, 16]}>
              {data.map((item, index) => (
                <Col key={index} span={8}>
                  <Card className={`${styles.template_card}`} hoverable>
                    <div className={`font-16 mb-2 ${styles.card_title}`}>
                      {item.title}
                    </div>
                    <div className=" mb-3 font-12">
                      <span>
                        <FileOutlined className={`${styles.icon_color}`} />
                        {'\u00a0'} {item.total_documents_count} formats
                      </span>
                      <span className="ml-4">
                        <UserGroup />
                        {'\u00a0'} 2 approvers
                      </span>
                    </div>
                    <div className={` font-12 mb-3`}>
                      <div className={` font-12 ${styles.icon_color}`}>
                        {' '}
                        Type
                      </div>
                      <div className={` font-12 ${styles.icon_color}`}>
                        {item.envelope_type[0].toUpperCase() +
                          item.envelope_type.substring(1)}
                      </div>
                    </div>
                    <div className={` font-12 mb-3`}>
                      <div className={` font-12 ${styles.icon_color}`}>
                        {' '}
                        Owner
                      </div>
                      <div className={`font-12`}>{item?.created_by}</div>
                    </div>
                    <div className={` font-12 mb-3`}>
                      <div className={` font-12 ${styles.icon_color}`}>
                        Created on
                      </div>
                      <div className={`font-12`}>
                        {moment(item.createdAt).format('Do MMMM YYYY')}
                      </div>
                    </div>
                    <div className={` font-12 `}>
                      <div className={` font-12 ${styles.icon_color_last}`}>
                        <span className="mr-3">
                          <DeleteOutlined
                            icon={<i className="mdi mdi-check" />}
                            onClick={() => handleDelete(item.id)}
                            style={{ fontSize: '22px' }}
                            className={
                              item.is_active
                                ? `${styles.not_allowed}`
                                : `${styles.allowed}`
                            }
                          />
                        </span>
                        <span>
                          <Tooltip key="comment-basic-like" title="Like">
                            <span
                              onClick={() => handlelike(item.id)}
                              className={
                                item.is_active
                                  ? `${styles.not_allowed}`
                                  : `${styles.allowed}`
                              }
                            >
                              {createElement(
                                item.favourite === true
                                  ? HeartFilled
                                  : HeartOutlined
                              )}
                            </span>
                          </Tooltip>
                        </span>
                        <span className="pull-right">
                          {item.is_active ? (
                            <CustomButton
                              className={`${styles.use_button} mr-2`}
                              disabled
                            >
                              Use
                            </CustomButton>
                          ) : (
                            <CustomButton
                              className={`${styles.use_button} mr-2`}
                            >
                              Use
                            </CustomButton>
                          )}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <div className={styles.empty_state}>
            <span>
              <Image
                className={`mb-2 ${styles.empty_image}`}
                alt="survey_empty_state"
                src={emptyState}
                preview={false}
              />
              <div className={styles.empty_state_text}>
                No favourite DoA envelopes.
              </div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default enevelopecard;
