import React from 'react';
import { Image, Form, Input, Typography } from 'antd';
import styles from './index.module.less';
import tataLogo from 'features/login/images/tata_logo.svg';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import Layout from '@/common/Layout';
import AuthWrapper from 'common/AuthWrapper';
// import { useParams } from 'react-router-dom';

const { Text } = Typography;
const queryParams = new URLSearchParams(window.location.search);
const email = queryParams.get('email');
const sentToken = queryParams.get('token');
const ChangePasswordPage = ({ loading, onSubmit }) => {
  const [form] = Form.useForm();
  const user = localStorage.getItem('laurus_login_user');
  const role = JSON.parse(user);
  const onFinish = (values) => {
    values['email'] = email === null ? role.user.email : email;
    values['sentToken'] = sentToken;
    onSubmit(values);
    `  `;
  };

  return (
    // <AuthWrapper>
    <>
      {role === null ? (
        <AuthWrapper>
          <div
            className={`df-jc-ac flex-column ${styles.right_outer_container}`}
          >
            <div className={styles.right_image}>
              <Image alt="tata_logo" src={tataLogo} preview={false} />
            </div>
            <div className="text-center mt-4">
              <Text className="d-block sfprotext-medium font-22 mb-1">{`Change your password`}</Text>
              {/* <Text className="d-block">{`Please enter the email address you’d like your password reset information sent to`}</Text> */}
            </div>
            <Form
              form={form}
              layout="vertical"
              className="mt-4 w-100"
              onFinish={onFinish}
            >
              <FormItem
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password placeholder="Enter New Password" />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password placeholder="Enter Confirm Password" />
              </FormItem>
              <FormItem>
                <CustomButton
                  className={`ant_button ant_button_secondary ${styles.secondary_button}`}
                  block
                  type="secondary"
                  htmlType="submit"
                  loading={loading}
                >
                  Reset
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </AuthWrapper>
      ) : (
        <Layout title={'Change Password'}>
          <div
            className={`df-jc-ac flex-column ${styles.right_outer_container_cp}`}
          >
            {/* <div className={styles.right_image}>
              <Image alt="laurus_logo" src={laurusLogo} preview={false} />
            </div> */}
            <div className="text-center mt-4">
              <Text className="d-block sfprotext-medium font-22 mb-1">{`Change your password`}</Text>
              {/* <Text className="d-block">{`Please enter the email address you’d like your password reset information sent to`}</Text> */}
            </div>
            <Form
              form={form}
              layout="vertical"
              className="mt-4 w-100"
              onFinish={onFinish}
            >
              <FormItem
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password placeholder="Enter New Password" />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password placeholder="Enter Confirm Password" />
              </FormItem>
              <FormItem>
                <CustomButton
                  block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Change
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </Layout>
      )}
    </>
    // </AuthWrapper>
  );
};
export default ChangePasswordPage;
