import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { AutoComplete, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';

// import { useParams } from 'react-router-dom';

import styles from './index.module.less';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

function ForwardModal({
  fileList,
  setFileList,
  // all_file_data,
  modalVisible,
  setModalVisible,
  onFinish,
  loading,
  allApprovers
}) {
  const [form] = Form.useForm();

  // const params = useParams();
  // const envelope_id = params.id;
  const data = allApprovers.map((item) => {
    const { email } = item;
    return {
      value: email
    };
  });

  return (
    <CustomModal
      visible={
        modalVisible === 'forward' ||
        modalVisible === 'forward-comments' ||
        modalVisible === 'rejected-comments'
          ? true
          : false
      }
      className={styles.background}
      title={
        modalVisible === 'forward'
          ? 'Forward the document'
          : 'Review the document'
      }
      onCancel={() => {
        setModalVisible('');
        setFileList([]);
      }}
      /*   width={'45%'} */
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason for forward and let others know..."
              rows={4}
              allowClear
            />
          </FormItem>
          {modalVisible === 'forward' ? (
            <FormItem
              label="Select reviewer"
              name="reviewer"
              rules={[
                {
                  required: true,
                  message: 'Required field'
                }
              ]}
            >
              <AutoComplete
                style={{ width: '100%' }}
                options={data}
                autoFocus
                showIcon={false}
                filterOption={true}
              />
            </FormItem>
          ) : (
            ''
          )}
          <FormItem
            label=""
            name="file"
            rules={[
              {
                required: false,
                message: 'Please attach files'
              }
            ]}
          >
            <Dragger
              multiple={true}
              listType="picture"
              showUploadList={true}
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
            >
              <p className="ant-upload-drag-icon">
                {/* Add upload icon or custom content */}
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                <b>Click or drag files</b> to upload
              </p>
            </Dragger>
          </FormItem>

          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => {
                setModalVisible('');
                setFileList([]);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              {modalVisible === 'forward' ? 'Forward' : 'Return'}
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default ForwardModal;
