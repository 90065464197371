import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row, Tag, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDesignationsList, getDesignationById } from '../../redux/slice';
import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';

const DesignationMaster = () => {
  const { all_designations, designationById, sbu_pagination, byIdLoading } =
    useSelector((state) => state.settings);
  const [id, setId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (id) => {
    console.log(id);
    dispatch(getDesignationById(id));
    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetAllDesignations = () => {
    let payload =
      search !== '' ? { search: search } : { search: search, page: page };
    dispatch(getAllDesignationsList(payload));
  };

  useEffect(() => {
    handleGetAllDesignations();
  }, [search, page]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const data = all_designations?.map((item) => {
    const { id, designation, designation_label, is_active } = item;
    return {
      ...item,
      status: { is_active },
      approvers: {
        designation
      },
      label: {
        designation_label
      },
      id: { id }
    };
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'approvers',
      key: 'approvers',
      render: (approvers) => <>{approvers?.designation}</>
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (label) => <div>{label?.designation_label}</div>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status?.is_active === true ? 'green' : 'red'}>
          {status?.is_active === true ? 'Active' : 'Deactivated'}
        </Tag>
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Tooltip title="Edit">
          <span onClick={() => showEditModal(id.id)}>
            <Pencil />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/designation"
              >
                Configure Designations
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold"
              type="primary"
            >
              Add Designation
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">Designations</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by name'
            }}
          />
        </Col>
      </Row>
      {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any flows yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add Designation
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: sbu_pagination?.total_records,
            showSizeChanger: false
          }}
          //loading={tableLoading}
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          /*  rowSelection={true} */
          dataSource={data}
          columns={columns}
        />
      )}
      {isModalOpen ? (
        <AddModal
          handleGetAllDesignations={handleGetAllDesignations}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          byIdLoading={byIdLoading}
          handleGetAllDesignations={handleGetAllDesignations}
          id={id}
          setId={setId}
          designationById={designationById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default DesignationMaster;
