import React from 'react';
// import { SmileOutlined } from '@ant-design/icons';
// import { Result } from 'antd';
import tataPro from './images/tata_logo.svg';
import tataRight from './images/Tata_logo 1.svg';
import mono from './images/Monochrome.svg';
import mono1 from './images/Monochrome 2.svg';
import mono2 from './images/Monochrome 3.svg';
import right_main from './images/Group 2097.svg';
import rect from './images/Rectangle 1134.svg';
import { Col, Row, Space } from 'antd';
import styles from './index.module.less';

function successPage() {
  const data = [
    {
      image: mono,
      description:
        'Send envelopes for digital signatures with standard and configurable flows and track their journey'
    },
    {
      image: mono1,
      description:
        'Review and approve business documents with digital timestamping and multi-factor authentication'
    },
    {
      image: mono2,
      description:
        'Search and retrieve final documents from cold storage with a unique envelope hash'
    }
  ];
  return (
    <>
      <div className={styles.left_screen}>
        <Row className={`mt-5 ml-5 mb-5`}>
          <Space size={35}>
            <img className={`${styles.left_logo}`} src={tataPro} />
          </Space>
        </Row>
        {/* <Result
          icon={<tataRight />}
          title="Thank you! The enveloped has been updated"
        /> */}
        <Row className={`mt-5 ml-5 mb-4 ${styles.main_header}`}>
          Thank you! The envelope has been updated
        </Row>

        <Row className="ml-5">
          {data.map((item, index) => (
            <Col className={`mb-2 ${styles.row_container}`} key={index}>
              <div className="df-jc-ac mb-4">
                <img src={item.image} />
              </div>
              <div className="df-jc-ac text-center">{item.description}</div>
            </Col>
          ))}
        </Row>
      </div>
      <div className={styles.right_screen}>
        <div className={`pull-right mt-4 mr-5`}>
          <img className={`${styles.right_logo}`} src={tataRight} />
        </div>
        <div>
          <img className={styles.rect_image} src={rect} />
        </div>
        <div>
          <img className={`${styles.overlay_image}`} src={right_main} />
        </div>
      </div>
    </>
  );
}

export default successPage;
