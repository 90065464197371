import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '@/common/Layout';
import DashBoardPage from './components/DashBoardPage';
import ADHOC from './images/ADHOC.svg';
import Standard from './images/Standard.svg';
import Custom from './images/Custom.svg';
import {
  getAllStats,
  getAllSubCategories,
  getAllPricebrackets,
  getAllCategories,
  getdoa,
  getAllProjects,
  getAllBUs
} from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
// import { history } from 'app/history';
// import { message } from 'antd';

const envelopeTypes = [
  {
    title: 'Initiate standard DoA envelope',
    subText:
      'Choose this for confidential documents or agreements with significant material impact',
    btnText: 'Initiate standard DoA envelope',
    image: Standard
  },
  {
    title: 'Initiate custom DoA envelope',
    subText:
      'Select this to send documents without a standard format along a preconfigured flow',
    btnText: 'Initiate custom DoA envelope',
    image: Custom
  },
  {
    title: 'Initiate ad-hoc envelope',
    subText:
      'Best suited for documents that do not have a defined division of authority process',
    btnText: 'Initiate ad-hoc envelope',
    image: ADHOC
  }
];

const DashBoard = () => {
  const [form] = Form.useForm();
  const { allStats, pricebrackets, categories, subcategories, projects, bu } =
    useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, []);

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStandardModalVisible, setIsStandardModalVisible] = useState(false);
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [isStandardTemplateModalVisible, setIsStandardTemplateModalVisible] =
    useState(false);
  const [standardValues, setStandardValues] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [searchproject, setSearchproject] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchSubCategory, setSearchSubCategory] = useState('');
  const [selectedBU, setSelectedBU] = useState([]);
  const [searchbu, setSearchbu] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [showProject, setShowProject] = useState(false);
  const [category, setCategory] = useState(categories);
  const [type, setType] = useState('');
  const [payloadDataForAllStandard, setPayloadDataForAllStandard] = useState(
    {}
  );

  const onFinish = (values) => {
    localStorage.setItem('envelope_name', values.envelope_name);
    localStorage.setItem('description', values.description);
    navigate('/initiate-envelope-1');
    form.resetFields();
  };

  const handleGetStats = () => {
    dispatch(getAllStats());
    dispatch(getAllPricebrackets());
  };

  useEffect(() => {
    const payload = { search: searchCategory };
    dispatch(getAllCategories(payload));
  }, [searchCategory]);

  useEffect(() => {
    handleGetAllProjects();
  }, [searchproject]);

  const handleGetAllProjects = () => {
    dispatch(getAllProjects(searchproject));
  };

  useEffect(() => {
    handleGetAllBU();
  }, [searchbu]);

  const handleGetAllBU = () => {
    dispatch(getAllBUs(searchbu));
  };

  const showModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const showStandardModal = (value) => {
    setIsStandardModalVisible(true);
    setType(value === 0 ? 'standard' : 'custom');
    form.resetFields();
  };

  const handleStandardCancel = () => {
    setIsStandardTemplateModalVisible(false);
    setIsStandardModalVisible(false);
    form.resetFields();
  };

  const handleStandardtemplateCancel = () => {
    setIsStandardTemplateModalVisible(false);
    form.resetFields();
  };

  const showCustomdModal = () => {
    setIsCustomModalVisible(true);
    form.resetFields();
  };

  const handleCustomCancel = () => {
    setIsCustomModalVisible(false);
    form.resetFields();
  };

  const handleCategory = (e) => {
    const payload = {
      category_id: e,
      sub_search: searchSubCategory
    };
    var filter = categories.filter((categories) => categories.id === e);

    filter[0].is_active_project ? setShowProject(true) : setShowProject(false);
    dispatch(getAllSubCategories(payload));
  };

  useEffect(() => {
    const payload = {
      category_id: categoryId,
      sub_search: searchSubCategory
    };
    dispatch(getAllSubCategories(payload));
  }, [searchSubCategory]);

  const handleSubCategory = (e) => {
    var filter = subcategories.filter(
      (subcategories) => subcategories.id === e
    );
    filter[0].is_active_project ? setShowProject(true) : setShowProject(false);
  };

  const onSelectProject = (data) => {
    setSelectedProject([...selectedProject, { project: data.project }]);
  };

  const onSearch = (value) => {
    setSearchproject(value);
  };

  const onSearchCategory = (value) => {
    setSearchCategory(value);
  };

  const onSearchSubCategory = (value) => {
    setSearchSubCategory(value);
  };
  const onSelectBU = (data) => {
    setSelectedBU([...selectedBU, { bu: data.bu }]);
  };

  const onSearchbu = (value) => {
    setSearchbu(value);
  };

  const handleSearchstandard = (payload) => {
    setPayloadDataForAllStandard(payload);
    dispatch(getdoa(payload)).then((response) => {
      response.payload.data
        ? (setStandardValues(response.payload.data),
          setIsStandardTemplateModalVisible(true),
          setIsStandardModalVisible(false))
        : // ? history.push(
          //     '/initiate-envelope-1/' +
          //       `${response.payload.data.id}?type=${payload.type}`
          //   )
          // : message.error(response.payload.message);
          (setIsStandardTemplateModalVisible(true),
          setIsStandardModalVisible(false));
    });
  };

  const stats = [
    {
      key: 1,
      label: 'Actions required',
      value: allStats?.action_required || 0,
      link: '/actions-required'
    },
    {
      key: 2,
      label: 'Waiting for others',
      value: allStats?.waiting_for_others || 0,
      link: '/pending'
    },
    {
      key: 3,
      label: 'Need Clarification',
      value: allStats?.hold || 0,
      link: '/hold'
    },
    {
      key: 4,
      label: 'Completed',
      value: allStats?.completed || 0,
      link: '/approvers'
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <DashBoardPage
          setSearchSubCategory={setSearchSubCategory}
          setCategoryId={setCategoryId}
          onSearchSubCategory={onSearchSubCategory}
          form={form}
          onSearchCategory={onSearchCategory}
          payloadDataForAllStandard={payloadDataForAllStandard}
          stats={stats}
          envelopeTypes={envelopeTypes}
          isModalVisible={isModalVisible}
          isStandardModalVisible={isStandardModalVisible}
          isCustomModalVisible={isCustomModalVisible}
          onFinish={onFinish}
          showModal={showModal}
          showCustomdModal={showCustomdModal}
          showStandardModal={showStandardModal}
          handleCancel={handleCancel}
          handleCustomCancel={handleCustomCancel}
          handleStandardCancel={handleStandardCancel}
          allStats={allStats}
          setIsCustomModalVisible={setIsCustomModalVisible}
          pricebrackets={pricebrackets}
          categories={categories}
          handleCategory={handleCategory}
          subcategories={subcategories}
          handleSearchstandard={handleSearchstandard}
          isStandardTemplateModalVisible={isStandardTemplateModalVisible}
          setIsStandardTemplateModalVisible={setIsStandardTemplateModalVisible}
          standardValues={standardValues}
          setStandardValues={setStandardValues}
          handleStandardtemplateCancel={handleStandardtemplateCancel}
          onSelectProject={onSelectProject}
          onSearch={onSearch}
          projects={projects}
          onSelectBU={onSelectBU}
          onSearchbu={onSearchbu}
          bu={bu}
          showProject={showProject}
          handleSubCategory={handleSubCategory}
          setCategory={setCategory}
          category={category}
          type={type}
        />
      </Layout>
    </>
  );
};

export default DashBoard;
