import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import forgotPasswordReducer from 'features/forgot-password/redux/slice';
import changePasswordReducer from '../features/change-password/redux/slice';
import approversReducer from '../features/initiate-envelope/redux/slice';
import adminReducer from '../features/admin/redux/slice';
import dashboardReducer from '../features/dashboard/redux/slice';
import favouritesReducer from '../features/favourites/redux/slice';
import trackersReducer from '../features/admin-manage/redux/slice';
import deleteenvReducer from '../features/deletedenvelopes/redux/slice';
import actionsReducer from '../features/actions-required/redux/slice';
import reviewActionsReducer from '../features/review-actions/redux/slice';
import exportActionsReducer from '../features/reports/redux/slice';
import settingsReducer from '../features/settings/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    changePassword: changePasswordReducer,
    approvers: approversReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    favourites: favouritesReducer,
    trackersList: trackersReducer,
    deleteenv: deleteenvReducer,
    actionsList: actionsReducer,
    reviewActions: reviewActionsReducer,
    exportActions: exportActionsReducer,
    settings: settingsReducer
  }
});
