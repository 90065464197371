import React, { useState } from 'react';
import styles from './index.module.less';
import { Form, Input, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
// import OtpInput from 'react-otp-input';
import TextArea from 'antd/lib/input/TextArea';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

function ApproveModal({
  fileList,
  setFileList,
  onFinish,
  loading,
  modalVisible,
  setModalVisible
}) {
  const [approversign, setApproversign] = useState('');
  const [form] = Form.useForm();

  return (
    <CustomModal
      visible={modalVisible === 'approved' ? true : false}
      className={styles.background}
      title={'Approve the document with signature'}
      onCancel={() => {
        setModalVisible('');
        setFileList([]);
      }}
      /* width={'40%'} */
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        <FormItem
          label="Enter the name or initial to sign"
          name="sign"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
          onChange={(e) => setApproversign(e.target.value)}
        >
          <Input placeholder="Enter name or initial" />
        </FormItem>
        {approversign != '' ? (
          <FormItem label="Signature preview" name="sign">
            <span className={`${styles.signpreview}`}>{approversign}</span>
          </FormItem>
        ) : (
          ''
        )}
        {/* <FormItem
          label="Enter OTP sent to your e-mail"
          name="otp"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <OtpInput
            numInputs={4}
            separator=""
            className={`${styles.otp}`}
          />
        </FormItem> */}
        <FormItem
          label="Comments"
          name="comments"
          rules={[
            {
              required: true,
              message: 'Please enter comments'
            }
          ]}
        >
          <TextArea
            style={{ width: '100%' }}
            placeholder="Add your reason for approved and let others know..."
            rows={4}
            allowClear
          />
        </FormItem>
        <FormItem
          label=""
          name="file"
          rules={[
            {
              required: false,
              message: 'Please attach files'
            }
          ]}
        >
          <Dragger
            multiple={true}
            listType="picture"
            showUploadList={true}
            fileList={fileList}
            onChange={({ fileList }) => setFileList(fileList)}
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess('ok');
              }, 0);
            }}
          >
            <p className="ant-upload-drag-icon">
              {/* Add upload icon or custom content */}
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              <b>Click or drag files</b> to upload
            </p>
          </Dragger>
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton
              htmlType="button"
              onClick={() => {
                setModalVisible('');
                setFileList([]);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Approve
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
}

export default ApproveModal;
