import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  review_Actions,
  get_otp,
  update_env,
  get_rejected_approvers
} from './api';

const initialState = {
  all_file_data: {},
  otp: '',
  rejected_approvers: [],
  allFileLoading: false
};

const actions = {
  GET_FILE_BY_ID: 'getfilebyid/GET_FILE_BY_ID',
  GET_OTP: 'getOtp/GET_OTP',
  UPDATE_ENVELOPE: 'update_envelope/UPDATE_ENVELOPE',
  GET_REJECTED_APPROVERS: 'getRejectedApprovers/GET_REJECTED_APPROVERS'
};

export const getfilebyid = createAsyncThunk(
  actions.GET_FILE_BY_ID,
  async (payload) => {
    const response = await review_Actions(
      `all-action-list?id=${payload.id}`,
      payload
    );
    return response;
  }
);

export const getOtp = createAsyncThunk(actions.GET_OTP, async (payload) => {
  const response = await get_otp(`send-otp`, payload);
  return response;
});

export const update_envelope = createAsyncThunk(
  actions.UPDATE_ENVELOPE,
  async (payload) => {
    const response = await update_env(payload);
    return response;
  }
);

export const getRejectedApprovers = createAsyncThunk(
  actions.GET_REJECTED_APPROVERS,
  async (payload) => {
    const response = await get_rejected_approvers(
      `env-accepters-list?envelope_id=${payload.envelope_id}`,
      payload
    );
    return response;
  }
);

export const getReviewsSlice = createSlice({
  name: 'reviewActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfilebyid.pending, (state) => {
        state.allFileLoading = true;
      })
      .addCase(getfilebyid.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_file_data = data;
        state.allFileLoading = false;
      })
      .addCase(getfilebyid.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.allFileLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOtp.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.otp = data;
      })
      .addCase(getOtp.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(update_envelope.pending, (state) => {
        state.loading = true;
      })
      .addCase(update_envelope.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(update_envelope.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getRejectedApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRejectedApprovers.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.rejected_approvers = data;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getRejectedApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getReviewsSlice.actions; */
/* export const { resetTrackers } = getReviewsSlice.actions; */

export default getReviewsSlice.reducer;
