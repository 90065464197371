import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip
} from 'antd';
import { CloudDownloadOutlined, RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
// import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDoaList, getAllSubCategories } from '../../redux/slice';
import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import { Link } from 'react-router-dom';
import CustomButton from '@/common/CustomButton';
import FormItem from '@/common/FormItem';

import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import CustomModal from '@/common/CustomModal';
import { getAllCategories } from '@/features/dashboard/redux/slice';
const DoaList = () => {
  const { all_Doa_List, pagination } = useSelector((state) => state.settings);

  // const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [catSearch, setCatSearch] = useState('');
  const [subCatSearch, setSubCatSearch] = useState('');

  const [catName, setCatName] = useState('');
  const [subCatName, setSubCatName] = useState('');
  const [form] = Form.useForm();
  const [showExportModal, setShowExportModal] = useState(false);

  const showExport = () => {
    setShowExportModal(true);
  };
  const closeModal = () => {
    setShowExportModal(false);
    form.resetFields();
  };

  const { categories } = useSelector((state) => state.approvers);

  console.log();
  console.log(all_Doa_List);
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [downloadReports, setDownloadReports] = useState(false);
  const onFinish = (values) => {
    setDownloadReports(true);
    const fromDate = values.fromDate
      ? `${moment(values?.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values?.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';

    console.log(fromDate, toDate);
    const user = LocalStorage.getItem('NFA_user');
    const authToken = user?.token;
    fetch(
      `${process.env.REACT_APP_API_URL}/envelope/download-doa?from_date=${fromDate}&to_date=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `DOA Reports ${moment(fromDate).format('DD-MMM-YY')} to ${moment(
            toDate
          ).format('DD-MMM-YY')}.csv`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloadReports(false);
        setShowExportModal(false);
        form.resetFields();
      });
  };

  const dispatch = useDispatch();

  const handleGetAllApprovers = () => {
    let payload = { page: page, catg: catName, sub_cat: subCatName };
    dispatch(getAllDoaList(payload));
  };

  useEffect(() => {
    let payload = { search: catSearch };
    dispatch(getAllCategories(payload));
  }, [catSearch]);

  const { all_subcategories } = useSelector((state) => state.settings);
  useEffect(() => {
    let payload = { search: subCatSearch };
    dispatch(getAllSubCategories(payload));
  }, [subCatSearch]);

  useEffect(() => {
    handleGetAllApprovers();
  }, [page, catName, subCatName]);

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  // };

  const data = all_Doa_List;
  const columns = [
    {
      title: 'Category',
      dataIndex: 'category.name',
      key: 'category.name',
      render: (category) => (
        <Tooltip placement="top" title={category}>
          <div className="text-caps">
            {' '}
            {category?.length > 20
              ? `${category?.substring(0, 15)}...`
              : category}
          </div>
        </Tooltip>
      )
    },
    {
      title: 'Sub-Category',
      dataIndex: 'sub_category.name',
      key: 'sub_category.name',
      render: (subcategory) => (
        <Tooltip placement="top" title={subcategory}>
          <div className="text-caps">
            {' '}
            {subcategory?.length > 30
              ? `${subcategory?.substring(0, 25)}...`
              : subcategory}
          </div>
        </Tooltip>
      )
    },

    {
      title: 'Budget',
      dataIndex: 'budget',
      key: 'budget',
      render: (text, record) => (
        <span>{`${record.min_price} - ${record.max_price}`}</span>
      )
    },
    {
      title: 'Condition',
      dataIndex: 'condition_text',
      key: 'condition_text',
      render: (subcategory) => (
        <>
          {subcategory ? (
            <Tooltip placement="top" title={subcategory}>
              <div className="text-caps">
                {' '}
                {subcategory?.length > 30
                  ? `${subcategory?.substring(0, 25)}...`
                  : subcategory}
              </div>
            </Tooltip>
          ) : (
            'NA'
          )}
        </>
      )
    },
    {
      align: 'center',

      title: 'Action',
      dataIndex: 'uuid',
      key: 'uuid',
      render: (uuid) => (
        <Tooltip title="Edit">
          <span className="cursor-pointer">
            <Link to={`/settings/edit-doa-flow/${uuid}`}>
              <Pencil />
            </Link>
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <>
      <CustomLayout sider={false}>
        {' '}
        <div className={`${styles.breadcrub_container}`}>
          {' '}
          <Row>
            <Col span={19}>
              <Breadcrumb separator={<RightOutlined />}>
                <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

                <Breadcrumb.Item
                  className="sfprotext-medium font-14"
                  href="/settings/doa-flows"
                >
                  Configure DOA Flows
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col className="text-right" span={4}>
              <Link to="/settings/create-doa-flow">
                <Button className="font-14 sfprotext-bold " type="primary">
                  Create DOA Flow
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className="mt-3 font-16 sfprotext-bold">DOAs</div>
          </Col>
          <Col span={3}>
            <CustomButton
              type="secondary"
              onClick={showExport}
              className={`mt-3`}
            >
              <CloudDownloadOutlined />
              Export
            </CustomButton>
          </Col>
          <Col className="mt-4" span={1}>
            Filter:
          </Col>
          <Col className={`mt-3 pull-right`} span={4}>
            <Select
              onClear={() => setCatName('')}
              allowClear
              onSelect={(e) => setCatName(e)}
              showSearch
              onSearch={(e) => setCatSearch(e)}
              className="w-100"
              placeholder="Category"
            >
              {' '}
              {categories.map((item, index) => {
                return (
                  <Select.Option value={item.name} key={index}>
                    {item.is_active
                      ? item.name
                      : item.name + ' - (Deactivated)'}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col className={`mt-3 pull-right`} span={4}>
            <Select
              onClear={() => setSubCatName('')}
              allowClear
              showSearch
              onSelect={(e) => setSubCatName(e)}
              onSearch={(e) => setSubCatSearch(e)}
              className="w-100"
              placeholder="Sub-Category"
            >
              {' '}
              {all_subcategories.map((item, index) => {
                return (
                  <Select.Option value={item.name} key={index}>
                    {item.is_active
                      ? item.name
                      : item.name + ' - (Deactivated)'}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        {data?.length === undefined ? (
          <>
            <div className="df-jc-ac mt-5">
              <img src={emptyState} />
            </div>
            <div className="df-jc-ac mt-3">
              <div className="sfprotext-regular font-14">
                You haven’t created any flows yet.
              </div>
            </div>
            <div className="df-jc-ac mt-3">
              <Link to="/settings/create-doa-flow">
                <Button type="primary" className="sfprotext-bold font-14">
                  Create DOA Flow
                </Button>
              </Link>
            </div>
          </>
        ) : (
          <CustomTable
            scroll={{ x: true }}
            //loading={tableLoading}
            rowKey={(uuid) => uuid.uuid}
            className={`mt-4 ${styles.custom_table}`}
            /*  rowSelection={true} */
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: 10,
              total: pagination?.total_records,
              showSizeChanger: false
            }}
            dataSource={data}
            columns={columns}
          />
        )}
      </CustomLayout>
      <CustomModal
        footer={
          <Form
            form={form}
            layout="vertical"
            className="ant-form ant-form-vertical"
            onFinish={onFinish}
          >
            <Row>
              <Col className="text-left" span={12}>
                <CustomButton onClick={closeModal}>Cancel</CustomButton>{' '}
              </Col>
              <Col className="text-right" span={12}>
                <CustomButton htmlType="submit" type="primary">
                  Download
                </CustomButton>
              </Col>
            </Row>
          </Form>
        }
        title="Download Reports"
        onCancel={closeModal}
        visible={showExportModal}
      >
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <Row>
            <Col className="gutter-row" span={24}>
              <FormItem
                label="Select start and end date"
                name="fromDate"
                rules={[
                  {
                    required: true,
                    message: 'Please enter date'
                  }
                ]}
              >
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(x) => {
                    setDateRange(x);
                  }}
                  disabledDate={(current) => {
                    return current && current.valueOf() > Date.now();
                  }}
                  value={dateRange}
                  separator={'-'}
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Modal visible={downloadReports} footer={false} closable={false}>
          <div style={{ textAlign: 'center' }} className="df-jc-ac">
            Downloading the report
          </div>
          <div className="mt-4 df-jc-ac">
            <Spin />
          </div>
        </Modal>
      </CustomModal>
    </>
  );
};

export default DoaList;
