import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { signin, verify } from './api';
import LocalStorage from 'utilities/localStorage';
// import { history } from '@/app/history';

const initialState = {
  loading: false,
  token: '',
  user: null
};

const actions = {
  LOGIN: 'login/LOGIN',
  VERIFY_SECRET: 'verify_secret/VERIFY_SECRET'
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);

  if (response?.token) {
    const { token, user_details } = response;
    let newUser = {
      token,
      user_details
    };

    LocalStorage.setItem('NFA_user', JSON.stringify(newUser));
    // LocalStorage.setItem('NFA_user_secret', { success: false });
    // if (newUser.user.role_id === 1) {
    //   history.push('/users');
    // } else if (newUser.user.role_id === 6) {
    //   history.push('/surveylist');
    // } else if (newUser.user.role_id === 3) {
    //   history.push('/doctors');
    // } else if (newUser.user.role_id === 5) {
    //   history.push('/doctors');
    // }
  }

  return response;
});

export const verify_secret = createAsyncThunk(
  actions.VERIFY_SECRET,
  async (payload) => {
    const response = await verify(payload);
    LocalStorage.setItem('NFA_user_secret', response);
    return response;
  }
);
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.token = '';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { token, message: msg, user } = action.payload;
        state.loading = false;
        state.token = token;
        state.user = user;

        if (token) {
          message.success({ content: msg, duration: 2 });
        } else {
          message.error(msg);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(verify_secret.pending, (state) => {
        state.loading = true;
      })
      .addCase(verify_secret.fulfilled, (state) => {
        // const { message: msg, success } = action.payload;
        state.loading = false;
      })
      .addCase(verify_secret.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export const { resetLoginDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  // console.log(instance);

  localStorage.clear();
  dispatch(resetLoginDetails());
  // instance.logoutPopup({
  //   postLogoutRedirectUri: '/'
  // });
  // message.success('Logged out successfully');
  // history.push('/login');
};

export default loginSlice.reducer;
