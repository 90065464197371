import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row, Spin, Tag, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllbus,
  getAllDesignationMappingsList,
  getAllDesignationsList,
  getAllProjects,
  getAllSbgs,
  getAllSbus,
  getDesignationMappingById
} from '../../redux/slice';
import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';
import { getAllApprovers } from '@/features/initiate-envelope/redux/slice';

const DesignationMapping = () => {
  const {
    byIdLoading,
    all_designations,
    all_designationMappings,
    designationMappingById,
    all_BUs,
    designation_pagination,
    all_Projects,
    all_SBUs,
    all_SBGs,
    designationLoading
  } = useSelector((state) => state.settings);

  const { allApprovers } = useSelector((state) => state.approvers);
  const [id, setId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [designSearch, setDesignSearch] = useState('');
  const [sbuSearch, setSbuSearch] = useState('');
  const [SBUSearch, setSBUSearch] = useState('');
  const [sbgName, setSbgName] = useState('');
  const [projectSearch, setProjectsSearch] = useState('');
  const [projectName, setProjectName] = useState('');
  const [SBUName, setSBUName] = useState('');
  const [designName, setDesignName] = useState('');
  const [sbuName, setSbuName] = useState('');
  const [page, setPage] = useState(1);
  const showModal = () => {
    setIsModalOpen(true);
  };

  console.log('AASASAS', all_Projects);
  const handleOk = () => {
    setIsModalOpen(false);
    setDesignName('');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setDesignName('');
  };

  const handleGetAllApprovers = () => {
    let payload = userSearch !== '' ? { search: userSearch } : {};
    dispatch(getAllApprovers(payload));
  };

  const showEditModal = (id) => {
    console.log(id);
    dispatch(getDesignationMappingById(id));
    handleGetAllSbus();
    handleGetAllSBUs();
    handleGetAllSbgs();
    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setSbuSearch('');
    setProjectsSearch('');
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetAllDesignations = () => {
    let payload =
      search !== '' ? { search: search } : { search: search, page: page };
    dispatch(getAllDesignationMappingsList(payload));
  };

  const handleGetAllSbus = () => {
    let payload = sbuSearch !== '' ? { search: sbuSearch } : {};
    dispatch(getAllbus(payload));
  };

  const handleGetAllSBUs = () => {
    let payload = SBUSearch !== '' ? { search: SBUSearch } : {};
    dispatch(getAllSbus(payload));
  };

  const handleGetAllProjects = () => {
    let payload = projectSearch !== '' ? { search: projectSearch } : {};
    dispatch(getAllProjects(payload));
  };

  const handleGetAllSbgs = () => {
    dispatch(getAllSbgs());
  };

  const handleGetAllDesignationsList = () => {
    let payload = { search: designSearch };
    dispatch(getAllDesignationsList(payload));
  };

  useEffect(() => {
    handleGetAllApprovers();
  }, [userSearch]);

  useEffect(() => {
    handleGetAllDesignationsList();
  }, [designSearch]);

  useEffect(() => {
    handleGetAllDesignations();
  }, [search, page]);

  useEffect(() => {
    handleGetAllSbus();
  }, [sbuSearch]);

  useEffect(() => {
    handleGetAllSbgs();
  }, []);

  useEffect(() => {
    handleGetAllSBUs();
  }, [SBUSearch]);

  useEffect(() => {
    handleGetAllProjects();
  }, [projectSearch]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleUserSearch = (e) => {
    setUserSearch(e);
  };

  const handleSbuSearchChange = (e) => {
    setSbuSearch(e);
    return e;
  };

  const handleSBUSearchChange = (e) => {
    setSBUSearch(e);
    return e;
  };

  const handleProjectSearchChange = (e) => {
    setProjectsSearch(e);
    return e;
  };

  const handleDesignationSearchChange = (e) => {
    setDesignSearch(e);
    return e;
  };

  const data = all_designationMappings?.map((item) => {
    const { id, sbu, sbg, is_active, project_name } = item;

    return {
      ...item,
      status: { is_active },
      sbu: {
        sbu
      },
      sbg: {
        sbg
      },
      id: { id },
      project: {
        project_name
      }
    };
  });

  const columns = [
    {
      width: '20%',
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      render: (designation) => <div>{designation ? designation : 'NA'}</div>
    },
    {
      width: '20%',
      title: 'SBU',
      dataIndex: 'sbu',
      key: 'sbu',
      render: (sbu) => <div>{sbu?.sbu ? sbu?.sbu : 'NA'}</div>
    },
    {
      width: '20%',
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
      render: (project) => (
        <div>{project?.project_name ? project?.project_name : 'NA'}</div>
      )
    },
    {
      width: '20%',
      title: 'User',
      dataIndex: 'user_full_name_email', // dataIndex for user.first_name
      key: 'user_full_name_email',
      render: (firstName) => {
        const fullName = `${firstName}`.trim();

        return (
          <Tooltip placement="top" title={fullName}>
            <div className="text-caps">
              {fullName.length > 30
                ? `${fullName.substring(0, 20)}...`
                : fullName}
            </div>
          </Tooltip>
        );
      }
    },
    {
      width: '10%',
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status?.is_active === true ? 'green' : 'red'}>
          {status?.is_active === true ? 'Active' : 'Deactivated'}
        </Tag>
      )
    },
    {
      width: '10%',
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Tooltip title="Edit">
          <span onClick={() => showEditModal(id.id)}>
            <Pencil />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/designation-mapping"
              >
                Configure Designation Mappings
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold"
              type="primary"
            >
              Map Designation
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">
            Designation Mappings
          </div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by SBU'
            }}
          />
        </Col>
      </Row>
      {designationLoading ? (
        <div
          style={{ flexDirection: 'column', height: '80vh' }}
          className="df-jc-ac h-100"
        >
          <Spin />
          Please wait
        </div>
      ) : data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any flows yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Map Designation
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          /*  rowSelection={true} */
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: designation_pagination?.total_records,
            showSizeChanger: false
          }}
          dataSource={data}
          columns={columns}
        />
      )}
      {isModalOpen ? (
        <AddModal
          all_SBGs={all_SBGs}
          setSbgName={setSbgName}
          setSBUName={setSBUName}
          all_SBUs={all_SBUs}
          allApprovers={allApprovers}
          handleUserSearch={handleUserSearch}
          setUserSearch={setUserSearch}
          designName={designName}
          setDesignName={setDesignName}
          all_designations={all_designations}
          handleGetAllDesignationsList={handleGetAllDesignationsList}
          projectName={projectName}
          setProjectName={setProjectName}
          sbuName={sbuName}
          setSbuName={setSbuName}
          all_Projects={all_Projects}
          handleProjectSearchChange={handleProjectSearchChange}
          all_BUs={all_BUs}
          handleDesignationSearchChange={handleDesignationSearchChange}
          handleSBUSearchChange={handleSBUSearchChange}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          setSbgName={setSbgName}
          sbgName={sbgName}
          all_SBGs={all_SBGs}
          SBUName={SBUName}
          setSBUName={setSBUName}
          all_SBUs={all_SBUs}
          byIdLoading={byIdLoading}
          allApprovers={allApprovers}
          setUserSearch={setUserSearch}
          handleUserSearch={handleUserSearch}
          sbuSearch={sbuSearch}
          setSbuSearch={setSbuSearch}
          handleDesignationSearchChange={handleDesignationSearchChange}
          setDesignName={setDesignName}
          designName={designName}
          all_designations={all_designations}
          sbuName={sbuName}
          setSbuName={setSbuName}
          projectName={projectName}
          setProjectName={setProjectName}
          all_Projects={all_Projects}
          handleProjectSearchChange={handleProjectSearchChange}
          all_BUs={all_BUs}
          handleSBUSearchChange={handleSBUSearchChange}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllDesignations={handleGetAllDesignations}
          id={id}
          setId={setId}
          designationMappingById={designationMappingById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default DesignationMapping;
