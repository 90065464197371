import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestResetPasswordLink } from './redux/slice';
import ForgotPasswordPage from './components/ForgotPasswordPage';

const ForgotPassword = () => {
  const { loading } = useSelector((state) => state.forgotPassword);
  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(requestResetPasswordLink(payload));
  };

  return <ForgotPasswordPage loading={loading} onSubmit={onSubmit} />;
};

export default ForgotPassword;
