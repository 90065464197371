import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { favenvelopes, deleteenvelop } from './api';

const initialState = {
  allFavEnvelopes: [],
  getAllEnvelopLoading: false
};

const actions = {
  GET_FAV_ALL_ENVELOPES: 'getAllfavEnvelopes/GET_ALL_FAV_ENVELOPES',
  DELETE_ENVELOPE: 'deleteEnvelope/DELETE_ENVELOPE'
};

export const getAllfavEnvelopes = createAsyncThunk(
  actions.GET_FAV_ALL_ENVELOPES,
  async (payload) => {
    const response = await favenvelopes(`get-fav`, payload);
    return response;
  }
);

export const deleteEnvelope = createAsyncThunk(
  actions.DELETE_ENVELOPE,
  async (payload) => {
    const response = await deleteenvelop(`delete-envelope`, payload);
    return response;
  }
);

export const favouriteSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllfavEnvelopes.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllfavEnvelopes.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.allFavEnvelopes = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(getAllfavEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteEnvelope.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(deleteEnvelope.fulfilled, (state, action) => {
        const { success, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(deleteEnvelope.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });
  }
});
export default favouriteSlice.reducer;
