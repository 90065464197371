import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createProjects } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Skeleton, Switch, message } from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const EditModal = ({
  setSbuSearch,
  byIdLoading,
  handleSbuSearchChange,
  all_BUs,
  setId,
  id,
  projectById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(projectById?.is_active);
  const [form] = Form.useForm();
  useEffect(() => {
    console.log('HH', projectById);
    setId(projectById?.id);
    setIsActive(projectById?.is_active);
    setSbuSearch(projectById?.unit?.name);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      is_active: projectById?.is_active,
      bu_id: projectById?.bu_id,
      project_id: projectById?.project_id,
      title: projectById?.title
    });
  }, [projectById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createProjects(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  console.log('AS', isActive);

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Project Details"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="mr-5" xs={24} xl={24} span={11}>
              <FormItem
                name="bu_id"
                label="BU"
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <Select
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  onSearch={handleSbuSearchChange}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select BU"
                >
                  {all_BUs?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.is_active === false
                        ? `${item.name} (Deactivated)`
                        : item.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Project Title'}
                name="title"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <Input placeholder="Enter Project" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Project Code'}
                name="project_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <Input placeholder="Enter Project Code" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Status'}
                name="is_active"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? `${styles.switcher_container}`
                      : `${styles.switcher_container1}`
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
