import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createBu } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Switch, message } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.less';
const { Option } = Select;

const EditModal = ({
  setSbuSearch,
  all_SBUs,
  handleSbuSearchChange,
  setId,
  id,
  buById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(buById?.is_active);
  useEffect(() => {
    setId(buById?.id);
    setIsActive(buById?.is_active);
    setSbuSearch(
      !buById?.sbu_master?.is_active ? '' : buById?.sbu_master?.sbu_name
    );
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      name: buById?.name,
      sbu_id: !buById?.sbu_master?.is_active ? undefined : buById?.sbu_id,
      is_active: buById?.is_active
    });
  }, [buById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createBu(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  const activeSBUs = all_SBUs?.filter((item) => item?.is_active);

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit BU Details"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'SBU'}
              name="sbu_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbuSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBU"
              >
                {activeSBUs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbu_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'BU Name'}
              name="name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter BU Name" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Active'}
              name="is_active"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <div
                className={
                  isActive
                    ? `${styles.switcher_container}`
                    : `${styles.switcher_container1}`
                }
              >
                <Switch checked={isActive} onChange={handleSwitchChange} />
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default EditModal;
