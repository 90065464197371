import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from 'common/Content/CustomTable';
import MessageIcon from '../images/shape.svg';
import {
  Badge,
  Menu,
  Popover,
  Progress,
  Modal,
  Col,
  Row,
  Timeline,
  Tooltip
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrackersList
  // exportcsv
  // resendEnvelope
} from '../redux/slice';
import styles from './index.module.less';
import moment from 'moment';

const ApprovedReceiptsPage = ({ selectedStatus, setSelectedStatus }) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '45%',
      render: (subject, record) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              <a
                href={`actions-required/review-actions/${record.id}`}
                target="__blank"
              >
                {subject.title}
                {`# Env-${record.id}`}
              </a>
            </b>
          </div>
          <div>
            To:{' '}
            {subject?.accepters?.length > 2
              ? subject.accepters.slice(0, 2).map((item) => item.name).join`, `
              : subject.accepters.map((item) => item.name).join`, `}
            {'\u00a0'}
            {record?.project_name ? `${' || '}${record?.project_name}` : ''}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {subject.accepters.map((item, index) => (
                    <p key={index}>{item.name}</p>
                  ))}
                </>
              }
              placement="rightTop"
            >
              {subject.accepters.length > 2 ? (
                <Badge
                  className={`site-badge-count-109 ${styles.badge}`}
                  count={`+ ${subject.accepters.length - 2}`}
                />
              ) : (
                ''
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <Row>
            <Col span={18}>
              <div className="mr-5 mb-1">
                <h1>
                  <Progress
                    size="small"
                    // success={{ percent: 30 }}
                    percent={`${Math.floor(
                      (description.present_approvers_count /
                        description.total_approvers_count) *
                        100
                    )}`}
                    strokeColor={'#1CB697'}
                    status="active"
                    format={gcd}
                    showInfo={false}
                  />
                </h1>
                <div>
                  {description.status === 'Accepted'
                    ? 'Approved'
                    : description.status === 'Hold'
                    ? 'Need Clarification'
                    : description.status}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-40px',
                fontSize: '13px',
                marginTop: '3px'
              }}
            >{`${description.present_approvers_count}/${description.total_approvers_count} done`}</Col>
          </Row>
        </>
      )
    }
  ];

  const { all_trackers, history_data, pagination } = useSelector(
    (state) => state.trackersList
  );
  const data = all_trackers.map((item) => {
    const {
      accepters,
      created_by,
      status,
      total_approvers_count,
      present_approvers_count,
      title
    } = item;
    return {
      ...item,
      subject: {
        accepters: accepters,
        created_by,
        title
      },
      description: {
        total_approvers_count,
        status,
        present_approvers_count
      }
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search, page]);
  const handleGetStats = () => {
    let payload =
      search !== '' ? { search: search, page: page } : { page: page };
    dispatch(getTrackersList(payload));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  // const handleResend = (id) => {
  //   dispatch(resendEnvelope(id));
  // };

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header
          justify="space-between"
          align="middle"
          // title="Sent"
          buttons={[]}
        />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by envelope or project'
            }}
            filters={false}
            statusMenu={statusMenu}
            style={{ marginTop: '-23px !important' }}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <CustomTable
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
          pagination={{
            size: 'small',
            total: pagination?.total_records || 0,
            onChange: (page) => {
              setPage(page);
            }
          }}
        />
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
            footer={false}
          >
            <Timeline>
              {history_data.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'Rejected'
                        ? 'red'
                        : item.status === 'Accepted'
                        ? 'green'
                        : item?.status === 'Cancelled'
                        ? 'red'
                        : 'blue'
                    }
                  >
                    {item.name}
                    {item.status !== null ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'Accepted'
                            ? 'Approved'
                            : item.status === 'Hold'
                            ? 'Need Clarification'
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {item.comments !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <img
                              src={MessageIcon}
                              className={`${styles.image_size}`}
                            />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.comments}>
                              {item?.comments.length > 40
                                ? item?.comments.substring(0, 40) + '...'
                                : item?.comments}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default ApprovedReceiptsPage;
