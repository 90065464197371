import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { createDesignationMappings } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Row, Select, message } from 'antd';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const AddModal = ({
  sbgName,
  SBUName,
  all_SBGs,
  setSbgName,
  setSBUName,
  handleSBUSearchChange,
  all_SBUs,
  allApprovers,
  handleUserSearch,
  setUserSearch,
  designName,
  setDesignName,
  all_designations,
  sbuName,
  setSbuName,
  setProjectName,
  projectName,
  all_Projects,
  handleProjectSearchChange,
  all_BUs,
  handleSbuSearchChange,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleDesignationSearchChange,
  handleGetAllDesignations
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isProjectActive, setIsProjectActive] = useState(true);
  const [isDesignActive, setIsDesignActive] = useState(true);

  // Update the handleSelect function to set the isDesignActive state
  const handleSelectDesign = (e) => {
    const selectedCategory = all_designations.find((item) => item.id === e);
    setIsDesignActive(selectedCategory?.is_active);
    // Rest of your handleSelect logic...
  };

  // Update the handleSelect function to set the isDesignActive state
  const handleSelect = (e) => {
    const selectedProject = all_Projects.find((item) => item.id === e);
    setIsProjectActive(selectedProject?.is_active);
    // Rest of your handleSelect logic...
  };

  const onFinish = (values) => {
    values['project_name'] = projectName;
    values['bu'] = sbuName;
    values['sbg'] = sbgName;
    values['sbu'] = SBUName;
    values['designation'] = designName.name;

    dispatch(createDesignationMappings(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllDesignations();
        setDesignName('');
        setProjectName('');
        setSbuName('');
        setSbgName('');
        setSBUName('');
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const onChangeDesignation = (value, option) => {
    if (option?.specificity === 'bu_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSBUName(null); // Clear SBU name state
      setSbgName(null);
      form.setFieldsValue({
        project_id: undefined,
        sbg_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'project_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      setSbgName(null);
      form.setFieldsValue({
        unit_id: undefined,
        sbu_id: undefined,
        sbg_id: undefined
      });
    } else if (option?.specificity === 'corporate') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'sbg_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSBUName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbu_id: undefined
      });
    } else if (option?.specificity === 'sbu_specific') {
      setDesignName({
        name: option?.children,
        specificity: option?.specificity
      });
      setProjectName(null); // Clear project name state
      setSbuName(null); // Clear SBU name state
      setSbgName(null);
      form.setFieldsValue({
        unit_id: undefined,
        project_id: undefined,
        sbg_id: undefined
      });
    }
  };

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                disabled={!isProjectActive || !isDesignActive}
                type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add Designation Mappings"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'Designation'}
              name="designation_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select Designation'
                }
              ]}
            >
              <Select
                onChange={onChangeDesignation}
                // Added line to save the specificity value
                onSelect={handleSelectDesign}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSearch={handleDesignationSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select Designation"
              >
                {all_designations?.map((item, index) => (
                  <Select.Option
                    specificity={item.specificity}
                    key={index}
                    value={item.id}
                  >
                    {item.is_active === false
                      ? `${item.designation} (Deactivated)`
                      : item.designation}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          {designName.specificity === 'bu_specific' ? (
            <Col span={24}>
              <FormItem
                label={'BU'}
                name="unit_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select BU'
                  }
                ]}
              >
                <Select
                  onChange={(value, option) => setSbuName(option.children)}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleSbuSearchChange}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select BU"
                >
                  {all_BUs?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.is_active === false
                        ? `${item.name} (Deactivated)`
                        : item.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          ) : designName.specificity === 'project_specific' ? (
            <Col span={24}>
              <FormItem
                label={'Project'}
                name="project_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select project'
                  }
                ]}
              >
                <Select
                  onChange={(value, option) => setProjectName(option.children)}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(e) => handleSelect(e)}
                  onSearch={handleProjectSearchChange}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select Project"
                >
                  {' '}
                  {all_Projects?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.is_active === false
                        ? `${item.project} (Deactivated)`
                        : item.project}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          ) : designName.specificity === 'sbg_specific' ? (
            <Col span={24}>
              <FormItem
                label={'SBG'}
                name="sbg_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select SBG'
                  }
                ]}
              >
                <Select
                  onChange={(value, option) => setSbgName(option.children)}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select SBG"
                >
                  {' '}
                  {all_SBGs?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.sbg_name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          ) : designName.specificity === 'sbu_specific' ? (
            <Col span={24}>
              <FormItem
                label={'SBU'}
                name="sbu_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select SBU'
                  }
                ]}
              >
                <Select
                  onChange={(value, option) => setSBUName(option.children)}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleSBUSearchChange}
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select SBU"
                >
                  {' '}
                  {all_SBUs?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.sbu_name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          ) : null}

          <Col span={24}>
            <FormItem
              label={'User'}
              name="user_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select user'
                }
              ]}
            >
              <Select
                onChange={(value, option) => setUserSearch(option.children)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSearch={handleUserSearch}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select User"
              >
                {' '}
                {allApprovers?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.is_active === false
                      ? `${item.name} (Deactivated)`
                      : item.name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
