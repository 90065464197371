import CustomLayout from '@/common/Layout';
import React, { useEffect, useState } from 'react';
import Enevelopecard from '@/common/enevelopecard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllfavEnvelopes, deleteEnvelope } from './redux/slice';

const favourites = () => {
  const { getAllEnvelopLoading, allFavEnvelopes } = useSelector(
    (state) => state.favourites
  );
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [typeValue, setTypeValue] = useState(1);
  const [deleteEnv, setDeleteEnv] = useState(1);

  useEffect(() => {
    handleGetAllFavEnvelopes();
  }, [deleteEnv]);

  const handleGetAllFavEnvelopes = () => {
    dispatch(getAllfavEnvelopes());
  };

  const handleDelete = (id) => {
    setDeleteEnv(id);
    dispatch(deleteEnvelope({ id: id }));
  };

  return (
    <>
      <CustomLayout sider={true}>
        <Enevelopecard
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          allFavEnvelopes={allFavEnvelopes}
          getAllEnvelopLoading={getAllEnvelopLoading}
          setTypeValue={setTypeValue}
          typeValue={typeValue}
          handleDelete={handleDelete}
        />
      </CustomLayout>
    </>
  );
};

export default favourites;
