import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { changePassword } from './api';
import { history } from 'app/history';

const initialState = {
  loading: false
};

const actions = {
  CHANGE_PASSWORD: 'forgotPassword/CHANGE_PASSWORD'
};

export const changepassword = createAsyncThunk(
  actions.CHANGE_PASSWORD,
  async (payload) => {
    const response = await changePassword(payload);
    localStorage.clear();
    message.success('Logged out successfully');
    history.push('/login');
    return response;
  }
);

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changepassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changepassword.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.loading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(changepassword.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default changePasswordSlice.reducer;
