import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  message
} from 'antd';
import styles from './index.module.less';
import {
  CaretDownFilled,
  CaretDownOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusCircleFilled,
  // DeleteOutlined,
  // HolderOutlined,
  // PlusCircleFilled,
  RightOutlined
} from '@ant-design/icons';
import FormItem from '@/common/FormItem';
import CustomLayout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSubCategories } from '@/features/initiate-envelope/redux/slice';
import { getAllCategories } from '@/features/dashboard/redux/slice';
import {
  createDoaFlow,
  getAllApproversList,
  // getAllbus,
  getAllDesignationsList,
  // getAllProjects,
  getAllSbgs
} from '@/features/settings/redux/slice';
import { debounce } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from '@/app/history';
// import Draggable from 'react-draggable';

const { Option } = Select;
const CreateDOAPage = () => {
  const user = getLoggedInUser();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { subcategories, categories } = useSelector((state) => state.approvers);
  const { all_approvers, all_designations } = useSelector(
    (state) => state.settings
  );
  const [radioButton, setRadioButton] = useState('value_based');
  const [anyValue, setAnyValue] = useState(false);
  //const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  // const [sbuSearch, setSbuSearch] = useState('');
  // const [projectSearch, setProjectSearch] = useState('');
  const [designSearch, setDesignSearch] = useState('');
  const [isProjectActive, setIsProjectActive] = useState(null);
  const [subSearch, setSubSearch] = useState('');

  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [categorySearch, setCategorySearch] = useState('');
  const [formValues, setFormValues] = useState([
    { sequence: 1, role_master_id: '', designation_id: '' }
  ]);

  // const business = [
  //   {
  //     id: 1,
  //     name: 'Urban Infrastructure'
  //   },
  //   {
  //     id: 2,
  //     name: 'Energy & Industrial'
  //   },
  //   {
  //     id: 3,
  //     name: 'Corporate'
  //   }
  // ];

  //const [sequenceCount, setSequenceCount] = useState(1);

  const onChangeRadioButton = (e) => {
    form.setFieldsValue({ doa_type: e.target.value });
    setRadioButton(e.target.value);
  };

  const handleGetAllApprovers = () => {
    let payload = { search: search };
    dispatch(getAllApproversList(payload));
  };

  // const handleGetAllProjects = () => {
  //   let payload = { search: projectSearch };
  //   dispatch(getAllProjects(payload));
  // };

  const handleGetAllDesignations = () => {
    let payload = { search: designSearch };
    dispatch(getAllDesignationsList(payload));
  };

  useEffect(() => {
    handleGetAllDesignations();
  }, [designSearch]);

  // useEffect(() => {
  //   handleGetAllProjects();
  // }, [projectSearch]);

  useEffect(() => {
    dispatch(getAllSbgs());
  }, []);

  const handleDesignSearchChange = (e) => {
    setDesignSearch(e);
  };

  // const handleProjectSearchChange = (e) => {
  //   setProjectSearch(e);
  // };

  const onFinish = (values) => {
    values['approvers'] = formValues;
    const { approvers, ...otherValues } = values;

    console.log(values);
    const doaFlows = approvers?.map((approver, index) => ({
      any_value: anyValue,
      doa_type: otherValues.doa_type,
      condition_text: otherValues.condition_text,
      sbg: otherValues.flow_type_id,
      designation_id: approver?.designation_id,
      project_id: otherValues.project_id,
      unit_id: otherValues.unit_id,
      user_id: user?.user_details?.id,
      category_id: otherValues.category_id,
      sub_category_id: otherValues.sub_category_id,
      sequence: index + 1,
      approval_role_id: approver?.role_master_id,
      max_price: otherValues?.max_price,
      min_price: otherValues?.min_price
    }));
    const data = {
      doa_flows: doaFlows
    };
    dispatch(createDoaFlow(data)).then((res) => {
      if (res.payload.success) {
        message.success(res.payload.message);
        history.push('/settings/doa-flows');
      } else {
        message.error(res.payload.message);
      }
    });
  };

  // const handleGetAllSBUs = () => {
  //   let payload = sbuSearch !== '' ? { search: sbuSearch } : { page: 1 };
  //   dispatch(getAllbus(payload));
  // };

  const toggleCheckbox = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({ min_price: 999, max_price: 999 });
    } else {
      form.setFieldsValue({ min_price: undefined, max_price: undefined });
    }
    setAnyValue(e.target.checked);
    console.log(e.target.checked);
  };

  console.log(anyValue);

  const addInput = () => {
    var highestValue = 1;
    for (var i = 1, len = formValues.length; i < len; i++) {
      var value = Number(formValues[i]['sequence']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setFormValues([
      ...formValues,
      { sequence: id, role_master_id: '', designation_id: '' }
    ]);
    const fieldName = `role_master_id${id}`;
    const fieldName1 = `designation_id${id}`;
    form.setFieldsValue({
      [fieldName]: undefined,
      [fieldName1]: undefined
    });
  };

  const handleRemoveFormItem = (sequence) => {
    if (sequence === 1) {
      // Do not remove sequence 0
      return;
    }

    const updatedValues = formValues.filter(
      (item) => item.sequence !== sequence
    );

    // Update the sequence numbers for the remaining items
    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);

    // Reset form fields for the remaining items
    const updatedFormValues = {};
    updatedValuesWithNewSequences.forEach((item) => {
      const fieldName = `role_master_id${item.sequence}`;
      const fieldName1 = `designation_id${item.sequence}`;
      updatedFormValues[fieldName] =
        item.role_master_id === '' ? undefined : item.role_master_id;
      updatedFormValues[fieldName1] =
        item.designation_id === '' ? undefined : item.designation_id;
    });
    form.setFieldsValue(updatedFormValues);
  };
  const onChangeApprover = (data, index) => {
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        return { ...obj, sequence: index + 1, role_master_id: data };
      }
      return obj;
    });

    setFormValues(newState);
  };

  const onChangeDesignation = (data, index) => {
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        return { ...obj, sequence: index + 1, designation_id: data };
      }
      return obj;
    });

    setFormValues(newState);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const updatedValues = [...formValues];

    // Reorder the dragged item in the array
    const [draggedItem] = updatedValues.splice(sourceIndex, 1);
    updatedValues.splice(destinationIndex, 0, draggedItem);

    // Update the sequence numbers
    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);
    const updatedFormValues = {};
    updatedValuesWithNewSequences.forEach((item) => {
      const fieldName = `role_master_id${item.sequence}`;
      const fieldName1 = `designation_id${item.sequence}`;
      updatedFormValues[fieldName] =
        item.role_master_id === '' ? undefined : item.role_master_id;
      updatedFormValues[fieldName1] =
        item.designation_id === '' ? undefined : item.designation_id;
    });
    form.setFieldsValue(updatedFormValues);
  };

  // Define the debounced version of the handleSearchChange function
  const debouncedHandleSearchChange = debounce((e) => {
    setSearch(e);
  }, 600);

  const handleSearchChange = (e) => {
    debouncedHandleSearchChange(e); // Call the debounced function
  };

  // handleSearchChange();

  const handleCategorySearch = (e) => {
    setCategorySearch(e);
  };

  const handleSubCategorySearch = (e) => {
    setSubSearch(e);
  };

  console.log(formValues);

  useEffect(() => {
    handleGetAllApprovers();
  }, [search]);

  const handleGetCategories = () => {
    const payload = categorySearch != '' ? { search: categorySearch } : {};
    dispatch(getAllCategories(payload));
  };

  const onChangeCategory = (id) => {
    setSelectedCategoryId(id);
    handleGetSubCategories(id);
    form.setFieldsValue({ sub_category_id: undefined });
    setIsProjectActive(null);
    form.setFieldsValue({ project_id: undefined });
  };

  const handleGetSubCategories = (id) => {
    const payload =
      subSearch != ''
        ? { sub_search: subSearch, category_id: id }
        : { category_id: id };
    dispatch(getAllSubCategories(payload));
  };

  useEffect(() => {
    handleGetSubCategories(selectedCategoryId); // Fetch subcategories when subSearch changes.
  }, [subSearch, selectedCategoryId]);

  useEffect(() => {
    handleGetCategories();
  }, [categorySearch]);

  const Bread = () => {
    return (
      <div className={`${styles.breadcrub_container}`}>
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/doa-flows"
              >
                Configure DOA Flows
              </Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/create-doa-flow"
              >
                Create New Flow
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              htmlType="submit"
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  };
  return (
    <CustomLayout sider={false}>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        initialValues={{ approvers: [''] }}
      >
        <Bread />
        <div className="mt-3">
          <Card className={`${styles.newForm}`} hoverable>
            <Row>
              <Col className="font-16 sfprotext-bold mb-4" span={24}>
                Select Flow Details
              </Col>
              {/* <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="flow_type_id"
                  label="SBG Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select SBG"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {' '}
                    {all_SBGs?.map((item, index) => (
                      <Select.Option key={index} value={item.sbg_name}>
                        {item.sbg_name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col> */}
              {/* <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="unit_id"
                  label="BU"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    showSearch
                    onSearch={(e) => setSbuSearch(e)}
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select BU"
                  >
                    {all_BUs?.map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.is_active === false
                          ? `${item.name} (Deactivated)`
                          : item.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col> */}
              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="category_id"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    onChange={onChangeCategory}
                    placeholder="Select Category"
                  >
                    {' '}
                    {categories
                      ?.filter((item) => item.new_cat_doa)
                      .map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="sub_category_id"
                  label="Sub-Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Sub-Category"
                  >
                    {subcategories
                      ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              {/* {isProjectActive && (
                <Col className="" xs={24} xl={11} span={11}>
                  <FormItem
                    name="project_id"
                    label="Project"
                    rules={[
                      {
                        required: true,
                        message: 'Please select'
                      }
                    ]}
                  >
                    <Select
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      showSearch
                      onSearch={handleProjectSearchChange}
                      suffixIcon={<CaretDownFilled />}
                      placeholder="Select Project"
                    >
                      {all_Projects?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.project} (Deactivated)`
                            : item.project}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
              )} */}
              <Col className="mr-5" xs={24} xl={24} span={11}>
                <FormItem
                  name="doa_type"
                  label="DOA type"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                  initialValue="value_based"
                >
                  <Radio.Group
                    onChange={onChangeRadioButton}
                    value={form.getFieldValue('doa_type')}
                  >
                    <Radio value={'value_based'}>Value-based</Radio>
                    <Radio value={'condition_based'}>Condition-based</Radio>
                    <Radio value={'both'}>Both</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
              {radioButton === 'value_based' || radioButton === 'both' ? (
                <>
                  <Col
                    className={`${isProjectActive ? 'ml-5' : 'mr-5'}`}
                    xs={24}
                    xl={11}
                    span={11}
                  >
                    <FormItem
                      name="min_price"
                      label="Budget (Min)"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a value'
                        }
                      ]}
                    >
                      <InputNumber
                        precision={0}
                        disabled={anyValue}
                        className="w-100"
                        placeholder="Enter Min Budget"
                      />
                    </FormItem>
                  </Col>
                  <Col className="" xs={24} xl={11} span={11}>
                    <FormItem
                      name="max_price"
                      label="Budget (Max)"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter a value'
                        }
                      ]}
                    >
                      <InputNumber
                        precision={0}
                        disabled={anyValue}
                        className="w-100"
                        placeholder="Enter Max Budget"
                      />
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <Checkbox
                      className="mb-3"
                      onChange={toggleCheckbox}
                      value={anyValue}
                      type="checkbox"
                    />{' '}
                    <span>I want to mark the budget as {`"Any Value"`}</span>
                  </Col>
                </>
              ) : null}

              {radioButton === 'condition_based' || radioButton === 'both' ? (
                <>
                  <Col
                    className={`${isProjectActive ? 'ml-5' : 'mr-5'}`}
                    xs={24}
                    xl={24}
                    span={24}
                  >
                    <FormItem
                      name="condition_text"
                      label="Condition"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter condition'
                        }
                      ]}
                    >
                      <Input.TextArea
                        className="w-100"
                        placeholder="Enter conditon"
                      />
                    </FormItem>
                  </Col>
                </>
              ) : null}
            </Row>
          </Card>
          <Card className={`mt-3 ${styles.newForm}`} hoverable>
            <Col className="mr-5" xs={24} xl={11} span={11}></Col>
            <Col className="font-16 sfprotext-bold mb-4" span={24}>
              Add Approvers
            </Col>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="formItems">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formValues.map((formItem, index) => (
                      <Draggable
                        key={formItem.sequence} // Use sequence as the key
                        draggableId={formItem.sequence.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Row
                              gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32
                              }}
                            >
                              <Col className="gutter-row" span={1}>
                                <div
                                  className={`${
                                    index < 0
                                      ? `styles.step ${styles.v_stepper}${styles.circle}`
                                      : ''
                                  } mt-2`}
                                >
                                  <div className={`${styles.circle}`}>
                                    <p>{index + 1}</p>
                                  </div>
                                  <div
                                    className={
                                      formValues.length !== index + 1 &&
                                      !snapshot.isDragging
                                        ? `${styles.line}`
                                        : ''
                                    }
                                  ></div>
                                </div>
                              </Col>
                              <Col className="gutter-row" xs={1} span={1}>
                                <div className="mt-2">
                                  <HolderOutlined />
                                </div>
                              </Col>
                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <div>
                                  <FormItem
                                    name={`role_master_id${formItem.sequence}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select'
                                      }
                                    ]}
                                  >
                                    <Select
                                      placeholder="Select Role"
                                      // value={formItem.role_master_id}
                                      showSearch
                                      onSearch={(e) => handleSearchChange(e)}
                                      suffixIcon={<CaretDownOutlined />}
                                      onSelect={
                                        (value) =>
                                          onChangeApprover(value, index) // Corrected parameter order
                                      }
                                    >
                                      {all_approvers?.map((approver) => (
                                        <Option
                                          key={approver.id}
                                          value={approver.id}
                                        >
                                          {approver.is_active === false
                                            ? `${approver.approval_role} (Deactivated)`
                                            : approver.approval_role}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </div>
                              </Col>

                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <div>
                                  <FormItem
                                    name={`designation_id${formItem.sequence}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please select'
                                      }
                                    ]}
                                  >
                                    <Select
                                      filterOption={(inputValue, option) =>
                                        option.props.children
                                          .toUpperCase()
                                          .indexOf(inputValue.toUpperCase()) !==
                                        -1
                                      }
                                      onSelect={
                                        (value) =>
                                          onChangeDesignation(value, index) // Corrected parameter order
                                      }
                                      showSearch
                                      onSearch={handleDesignSearchChange}
                                      suffixIcon={<CaretDownFilled />}
                                      placeholder="Select Designation"
                                    >
                                      {all_designations?.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                          {item.is_active === false
                                            ? `${item.designation} (Deactivated)`
                                            : item.designation}
                                        </Option>
                                      ))}
                                    </Select>
                                  </FormItem>
                                </div>
                              </Col>

                              <Col
                                className="gutter-row"
                                xs={3}
                                xl={6}
                                span={6}
                              >
                                <div>
                                  {index > 0 && (
                                    <DeleteOutlined
                                      onClick={() =>
                                        handleRemoveFormItem(formItem.sequence)
                                      }
                                      className={`cursor-pointer ${styles.delete_icon}`}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <div>
              {formValues.map((item, index) => (
                <div key={index}>
                  <span>Sequence: {item.sequence}</span>
                  <span>Role ID: {item.role_master_id}</span>
                </div>
              ))}
            </div> */}

            <span
              onClick={addInput}
              className={`cursor-pointer ${styles.span_add}`}
            >
              <PlusCircleFilled />
              {'\u00a0'} Add Roles
            </span>
          </Card>
        </div>
      </Form>
    </CustomLayout>
  );
};

export default CreateDOAPage;
