import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import CustomTable from 'common/Content/CustomTable';
import {
  BackwardFilled,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ForwardFilled,
  LoadingOutlined,
  PauseOutlined,
  StopOutlined
} from '@ant-design/icons';
import MessageIcon from '../images/shape.svg';
import {
  Badge,
  Dropdown,
  Menu,
  Space,
  Popover,
  Progress,
  Modal,
  Col,
  Row,
  Timeline,
  Tooltip
} from 'antd';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTrackersList,
  getHistoryList
  // exportcsv
  // resendEnvelope
} from '../redux/slice';
import styles from './index.module.less';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';

const AdminManagePage = ({ selectedStatus, setSelectedStatus }) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [search, setSearch] = useState('');
  // const [page, setPage] = useState(1);
  //asas
  const current_status =
    window?.location?.pathname?.replace('/', '') === 'pending'
      ? 'waiting for others'
      : window?.location?.pathname?.replace('/', '') === 'approvers'
      ? 'completed'
      : window?.location?.pathname?.replace('/', '') === 'cancelled'
      ? 'Cancelled'
      : window?.location?.pathname?.replace('/', '') === 'information'
      ? 'information'
      : window?.location?.pathname?.replace('/', '') === 'rejections'
      ? 'my_rejected'
      : window?.location?.pathname?.replace('/', '');

  const user = getLoggedInUser();

  const current_id = user?.user_details?.id;

  const gcd = function (a, b) {
    if (b < 0.0000001) return a; // Since there is a limited precision we need to limit the value.

    return gcd(b, Math.floor(a % b)); // Discard any fractions due to limitations in precision.
  };

  const menu = (id) => (
    <Menu
      items={[
        {
          label: <span onClick={() => handleHistory(id)}>Audit Trail</span>,
          key: '0'
        },
        {
          label: (
            <span onClick={() => handleExportasCsv(id)}>Export as CSV</span>
          ),
          key: '1'
        }
      ]}
    />
  );
  const columns = [
    {
      key: 'as',
      title: '',
      dataIndex: 'icons',
      render: (test, tesa) => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {tesa.status === 'Cancelled' ? (
                <StopOutlined style={{ color: 'red' }} />
              ) : tesa.status === 'Waiting for others' ? (
                <ClockCircleOutlined />
              ) : tesa.status === 'Rejected' ? (
                <CloseCircleOutlined style={{ color: 'red' }} />
              ) : tesa.status === 'Completed' || tesa.status === 'Accepted' ? (
                <CheckCircleOutlined style={{ color: '#090' }} />
              ) : tesa.status === 'Need Clarification' ||
                tesa.status === 'Hold' ? (
                <PauseOutlined style={{ color: 'blue' }} />
              ) : tesa.status === 'Forwarded' ? (
                <ForwardFilled style={{ color: 'blue' }} />
              ) : tesa.status === 'Returned' ? (
                <BackwardFilled style={{ color: 'blue' }} />
              ) : (
                <ClockCircleOutlined />
              )}
            </div>
          </>
        );
      }
    },
    {
      title: 'Type',
      dataIndex: 'envelope_type',
      key: 'envelope_type',
      width: '10%',
      render: (envelope_type) => (
        <div style={{ textTransform: 'capitalize' }}>{envelope_type}</div>
      )
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      width: '40%',
      render: (subject, record) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">
              <a
                href={`actions-required/review-actions/${record.id}`}
                target="__blank"
              >
                {subject.title}
                {`# Env-${
                  record['envelope_flow.envelope_flows_id']
                    ? record['envelope_flow.envelope_flows_id']
                    : record?.envelope_flows_id
                }`}
              </a>
            </b>
          </div>
          <div>
            To:{' '}
            {subject?.accepters?.length
              ? subject?.accepters?.length > 2
                ? subject.accepters.slice(0, 2).map((item) => item.name)
                    .join`, `
                : subject.accepters.map((item) => item.name).join`, `
              : ''}
            {'\u00a0'}
            {record?.project_name ? `${' || '}${record?.project_name}` : ''}
            <Popover
              content={
                <>
                  <h2 className="sfprotext-bold">Approvers</h2>
                  {subject?.accepters?.length
                    ? subject.accepters.map((item, index) => (
                        <p key={index}>{item.name}</p>
                      ))
                    : ''}
                </>
              }
              placement="rightTop"
            >
              {subject?.accepters?.length ? (
                subject.accepters.length > 2 ? (
                  <Badge
                    className={`site-badge-count-109 ${styles.badge}`}
                    count={`+ ${subject.accepters.length - 2}`}
                  />
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </Popover>
          </div>
        </>
      )
    },
    {
      title: 'Status',
      key: 'description',
      dataIndex: 'description',
      width: '30%',
      render: (description) => (
        <>
          <Row>
            <Col span={18}>
              <div className="mr-5 mb-1">
                <h1>
                  <Progress
                    size="small"
                    // success={{ percent: 30 }}
                    percent={`${Math.floor(
                      (description.present_approvers_count /
                        description.total_approvers_count) *
                        100
                    )}`}
                    strokeColor={'#1CB697'}
                    status="active"
                    format={gcd}
                    showInfo={false}
                  />
                </h1>
                <div>
                  {description.status === 'Accepted'
                    ? 'Approved'
                    : description.status === 'Hold'
                    ? 'Need Clarification'
                    : description.status}
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                marginLeft: '-40px',
                fontSize: '13px',
                marginTop: '3px'
              }}
            >{`${description.present_approvers_count}/${description.total_approvers_count} done`}</Col>
          </Row>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '20%',
      render: (text, record) => {
        return (
          <>
            {window?.location?.pathname?.replace('/', '') === 'hold' ? (
              <Link to={'/actions-required/review-actions/' + `${record.id}`}>
                <CustomButton type="primary" className={`${styles.use_button}`}>
                  {'Release'}
                </CustomButton>
              </Link>
            ) : window?.location?.pathname?.replace('/', '') === 'approvers' ? (
              <CustomButton className={`${styles.use_button} mr-2`}>
                <a
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/user/fetch_file?blobName=${record?.document_url
                    ?.split('/')
                    ?.pop()}`}
                  download
                >
                  Download
                </a>
              </CustomButton>
            ) : (
              <CustomButton className={`${styles.use_button} mr-2`}>
                {record.status === 'Completed' ? (
                  <a href={`/final-review/${record.id}`} target="__blank">
                    View
                  </a>
                ) : (
                  <a
                    href={`/actions-required/review-actions/${record.id}`}
                    target="__blank"
                  >
                    View
                  </a>
                )}
              </CustomButton>
            )}

            <CustomButton className={`${styles.down_button}`}>
              <Dropdown overlay={menu(record.id)} trigger={['click']}>
                <a onClick={(e) => e.preventDefault(e)}>
                  <Space>
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </CustomButton>
          </>
        );
      }
    }
  ];

  const { all_trackers, history_data, loading } = useSelector(
    (state) => state.trackersList
  );
  const data = all_trackers.map((item) => {
    const {
      accepters,
      created_by,
      status,
      total_approvers_count,
      present_approvers_count,
      title,
      envelope_type
    } = item;
    return {
      ...item,
      subject: {
        accepters: accepters,
        created_by,
        title,
        envelope_type
      },
      description: {
        total_approvers_count,
        status,
        present_approvers_count
      }
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search]);
  const handleGetStats = () => {
    let payload = {};

    if (search !== '') {
      payload[current_status === 'information' ? 'search_cat' : 'search'] =
        search;
    }

    if (current_status !== 'manage' && current_status !== 'information') {
      payload['status'] = current_status;
    }

    if (current_status === 'information') {
      payload['user_id'] = current_id;
    }

    dispatch(getTrackersList(payload));
  };

  const onCancel = () => {
    setShowHistoryModal(false);
    let payload = {};

    if (search !== '') {
      payload[current_status === 'information' ? 'search_cat' : 'search'] =
        search;
    }

    if (current_status !== 'manage' && current_status !== 'information') {
      payload['status'] = current_status;
    }

    if (current_status === 'information') {
      payload['user_id'] = current_id;
    }

    dispatch(getTrackersList(payload));
  };

  const handleHistory = (id) => {
    setShowHistoryModal(true);
    dispatch(getHistoryList(id));
  };

  const handleExportasCsv = (id) => {
    // dispatch(exportcsv(id));
    const user = localStorage.getItem('NFA_user');
    const authToken = JSON.parse(user);
    fetch(`https://nfa-api.lexyslabs.com/api/v1/envelope/download-csv/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `Bearer ${authToken.token}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `history.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  // const handleResend = (id) => {
  //   dispatch(resendEnvelope(id));
  // };
  const customSpinIcon = (
    <div className="df-jc-ac">
      {' '}
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </div>
  );

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header
          justify="space-between"
          align="middle"
          // title="Sent"
          buttons={[]}
        />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by envelope or project'
            }}
            filters={false}
            statusMenu={statusMenu}
            style={{ marginTop: '-23px !important' }}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <CustomTable
          rowKey={(obj) => obj.id}
          loading={{
            spinning: loading, // Set to true to show loading spinner
            indicator: customSpinIcon // Customize the spin icon
          }}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 10,
            total: all_trackers.total_records,
            showSizeChanger: false
          }}
        />
        {showHistoryModal ? (
          <Modal
            title="Audit Trail"
            visible={showHistoryModal}
            onCancel={onCancel}
            footer={false}
          >
            <Timeline>
              {history_data.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    color={
                      item.status === 'Rejected'
                        ? 'red'
                        : item.status === 'Accepted'
                        ? 'green'
                        : item.status === 'Cancelled'
                        ? 'red'
                        : 'blue'
                    }
                  >
                    {item.name}
                    {item.status !== null ? (
                      <div className={`${styles.content_text}`}>
                        <span style={{ fontSize: '9px' }}>{`${
                          item.status === 'Accepted'
                            ? 'Approved'
                            : item.status === 'Hold'
                            ? 'Need Clarification'
                            : item.status
                        } on ${moment(item.updatedAt).format(
                          'DD MMM YY, h:mm A'
                        )}`}</span>
                      </div>
                    ) : (
                      ''
                    )}
                    {item.comments !== null ? (
                      <>
                        <div className={`${styles.content_text}`}>
                          <span style={{ fontSize: '9px' }}>
                            <img
                              src={MessageIcon}
                              className={`${styles.image_size}`}
                            />
                          </span>
                          <span style={{ marginLeft: '4px', fontSize: '8px' }}>
                            <Tooltip placement="top" title={item?.comments}>
                              {item?.comments.length > 40
                                ? item?.comments.substring(0, 40) + '...'
                                : item?.comments}
                            </Tooltip>
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default AdminManagePage;
