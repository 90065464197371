import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;
export const dx_apiUrl = 'https://dx-api.tataprojects.com/api/v1';

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('NFA_user');
  const authToken = user?.token;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/user/signin`,
    resetPassword: `${apiUrl}/users/reset-password`,
    approvers: `${apiUrl}/approvers`,
    create_envelop: `${apiUrl}/envelope`,
    admin: `${apiUrl}/admin`,
    all_admin_standard_envelopes: `${apiUrl}/admin/all-standard`,
    admin_tracker: `${apiUrl}/envelope`,
    all_standard_envelopes: `${apiUrl}/envelope`,
    all_actions_required: `${apiUrl}/envelope`,
    final_view: `${apiUrl}/user/envelope`,
    otp: `${apiUrl}/approvers`,
    unit: `${apiUrl}/unit`,
    price: `${apiUrl}/price`,
    categories: `${apiUrl}/category`,
    subcategories: `${apiUrl}/sub-category`,
    verify: `${dx_apiUrl}/secret/verify_secret`,
    all_sbu: `${apiUrl}/sbu`,
    all_sbg: `${apiUrl}/sbg`,
    all_approvers: `${apiUrl}/approval_role`,
    all_designations: `${apiUrl}/designation_master`,
    all_mappings: `${apiUrl}/designation_mapping`,
    all_projects: `${apiUrl}/admin/all-projects`,

    create_designation_mappings: `${apiUrl}/envelope/create-designation-mapping`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth, type) {
    return apiClient.make(url, 'PATCH', params, auth, type);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
