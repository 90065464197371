import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createSbu } from '@/features/settings/redux/slice';
import { Col, Form, Input, Row, Select, Switch, message } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.less';
import { CaretDownFilled } from '@ant-design/icons';
const { Option } = Select;

const EditModal = ({
  handleSbgSearchChange,
  all_SBGs,
  setId,
  id,
  sbuById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllApprovers
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(sbuById?.is_active);
  useEffect(() => {
    setId(sbuById?.id);
    setIsActive(sbuById?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      sbu_name: sbuById?.sbu_name,
      sbg_master_id:
        sbuById?.sbg_master?.is_active === false
          ? undefined
          : sbuById?.sbg_master_id,
      is_active: sbuById?.is_active
    });
  }, [sbuById, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createSbu(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };
  const activeSBGs = all_SBGs?.filter((item) => item?.is_active);

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit SBU Details"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'SBG'}
              name="sbg_master_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbgSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBG"
              >
                {activeSBGs?.map((item, index) => (
                  <Option value={item?.id} key={index}>
                    {item?.sbg_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'SBU Name'}
              name="sbu_name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter SBU Name" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label={'Active'}
              name="is_active"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <div
                className={
                  isActive
                    ? `${styles.switcher_container}`
                    : `${styles.switcher_container1}`
                }
              >
                <Switch
                  disabled={!sbuById?.is_active}
                  checked={isActive}
                  onChange={handleSwitchChange}
                />
              </div>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default EditModal;
