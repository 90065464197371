import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createSubCategory } from '@/features/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Skeleton, Switch, message } from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EditModal = ({
  byIdLoading,
  handleCategorySearchChange,
  categories,
  setId,
  id,
  subcategory_by_id,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetSubCategories,
  setCategorySearch
}) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(subcategory_by_id?.is_active);
  const [isCategoryActive, setIsCategoryActive] = useState(true);

  // Update the handleSelect function to set the isCategoryActive state
  const handleSelect = (e) => {
    const selectedCategory = categories.find((item) => item.id === e);
    setIsCategoryActive(selectedCategory?.is_active);
    // Rest of your handleSelect logic...
  };
  // const [checked, setChecked] = useState(false);

  // const handleChange = (checked) => {
  //   setChecked(checked);
  // };
  const [form] = Form.useForm();
  useEffect(() => {
    console.log('HH', subcategory_by_id);
    setCategorySearch(subcategory_by_id?.category?.name);
    setId(subcategory_by_id?.id);
    setIsActive(subcategory_by_id?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      is_active: subcategory_by_id?.is_active,
      name: subcategory_by_id?.name,
      category_id: subcategory_by_id?.category_id,
      sub_specificity: subcategory_by_id?.sub_specificity
    });
  }, [subcategory_by_id, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['is_active'] = isActive;
    dispatch(createSubCategory(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetSubCategories();
      } else {
        message.error(response.payload.message);
      }
    });
  };
  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };

  const specificity = [
    {
      value: 'project_specific',
      name: 'Project'
    },
    {
      value: 'bu_specific',
      name: 'BU'
    },
    {
      value: 'sbu_specific',
      name: 'SBU'
    },
    {
      value: 'sbg_specific',
      name: 'SBG'
    },
    {
      value: 'corporate',
      name: 'Corporate'
    }
  ];
  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                disabled={!isCategoryActive}
                type="primary"
                htmlType="submit"
              >
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Sub-Category Details"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label={'Category'}
                name="category_id"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <Select
                  suffixIcon={<CaretDownFilled />}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(e) => handleSelect(e)}
                  onSearch={(e) => handleCategorySearchChange(e)}
                  placeholder="Select Category"
                >
                  {categories?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.is_active === false
                        ? `${item.name} (Deactivated)`
                        : item.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Sub-Category Name'}
                name="name"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <Input placeholder="Enter Sub-Category Name" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Specificity'}
                name="sub_specificity"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please select'
                  }
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={<CaretDownFilled />}
                  placeholder="Select Specificity"
                >
                  {specificity?.map((item, idx) => (
                    <Select.Option key={idx} value={item.value}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Status'}
                name="is_active"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? `${styles.switcher_container}`
                      : `${styles.switcher_container1}`
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
