import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { resetPassword } from './api';

const initialState = {
  loading: false
};

const actions = {
  REQUEST_RESET_PASSWORD_LINK: 'forgotPassword/REQUEST_RESET_PASSWORD_LINK'
};

export const requestResetPasswordLink = createAsyncThunk(
  actions.REQUEST_RESET_PASSWORD_LINK,
  async (payload) => {
    const response = await resetPassword(payload);

    return response;
  }
);

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestResetPasswordLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(requestResetPasswordLink.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.loading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(requestResetPasswordLink.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export default forgotPasswordSlice.reducer;
