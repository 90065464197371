import React from 'react';
import { Row, Col, Image } from 'antd';
import styles from './index.module.less';
import loginImage from 'features/login/images/login_image.png';

const AuthWrapper = ({ children }) => {
  return (
    <Row className={styles.container}>
      <Col span={13}>
        <div className={styles.left_outer_container}>
          <div>
            <div className={`sfprotext-bold ${styles.left_text}`}>
              {`Manage your documents workflow`}
            </div>
            <div className={`sfprotext-regular ${styles.left_text_2}`}>
              {`Make your job easy in creating envelopes and sharing it across for approvals. Also its very easy to track the shared documents and creating templates of your own`}
            </div>
          </div>
          <div className={styles.left_image}>
            <Image alt="login_image" src={loginImage} preview={false} />
          </div>
        </div>
      </Col>
      <Col span={11}>{children}</Col>
    </Row>
  );
};

export default AuthWrapper;
