import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { exportcsv } from './api';

const initialState = {};

const actions = {
  EXPORT_ENVELOPES: 'export/EXPORT_ENVELOPES'
};

export const exportEnvelopes = createAsyncThunk(
  actions.EXPORT_ENVELOPES,
  async (payload) => {
    const response = await exportcsv('download-csv', payload);
    return response;
  }
);
export const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(exportEnvelopes.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(exportEnvelopes.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (success) {
          message.error(msg);
        }
      })
      .addCase(exportEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
  }
});
export default exportSlice.reducer;
