import React, { useEffect, useState } from 'react';
import CustomModal from 'common/CustomModal';

import {
  Form,
  Row,
  Col,
  Card,
  Menu,
  Image,
  Alert,
  message,
  Modal,
  Timeline,
  Button,
  Tooltip
} from 'antd';
import CustomButton from 'common/CustomButton';
import styles from './index.module.less';
import UserGroup from '@/features/admin/images/UserGroupsvg';
import emptyState from '../images/empty.svg';
import SearchBar from '@/common/Content/searchBar';

import { useDispatch, useSelector } from 'react-redux';
import {
  getAllStandardEnvelopes,
  getAllCustomEnvelopes
} from '../../redux/slice';
import moment from 'moment';

import {
  ExclamationCircleOutlined,
  FileOutlined
  // InfoCircleFilled
} from '@ant-design/icons';
import { history } from '@/app/history';
// import LocalStorage from 'utilities/localStorage'
// import { Link } from 'react-router-dom';

const AddCustomModal = ({
  payloadDataForAllStandard,
  selectedStatus,
  setSelectedStatus,
  isStandardTemplateModalVisible,
  // setIsStandardTemplateModalVisible,
  handleStandardtemplateCancel,
  // isStandardModalVisible,
  onFinish,
  handleCustomCancel,
  isCustomModalVisible,
  type,
  standardValues
}) => {
  const [form] = Form.useForm();
  console.log('standardValues', payloadDataForAllStandard);

  // const { allStandardEnvelopes, allCustomEnvelopes } = useSelector(
  //   (state) => state.dashboard
  // );
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [approverData, setApproverData] = useState([]);
  const { allCustomEnvelopes } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, []);
  const handleGetStats = () => {
    type === 'custom'
      ? dispatch(getAllCustomEnvelopes())
      : dispatch(getAllStandardEnvelopes());
  };
  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const handleUse = (values) => {
    localStorage.setItem('envelope_name', values.title);
    localStorage.setItem('description', values.description);
    localStorage.setItem(
      'project',
      values.project !== null ? values.project.id : 'null'
    );
    const payload = {
      id: values.id,
      unit_id: payloadDataForAllStandard.unit_id,
      category_id: payloadDataForAllStandard.category_id,
      sub_category_id: payloadDataForAllStandard.sub_category_id,
      project_id: payloadDataForAllStandard.project_id || null
    };
    dispatch(getAllStandardEnvelopes(payload))
      .then((res) => {
        const hasNullEmailOrApprover = res?.payload?.data?.approvers?.some(
          (item) => item.email === null || item.name === null
        );

        if (hasNullEmailOrApprover) {
          setApproverData(res.payload.data?.approvers);
          setShowHistoryModal(true);
        } else {
          history.push(
            '/initiate-envelope-1/' +
              `${values.id}?type=${values.envelope_type}`
          );
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  // const price = (value) => {
  //   let prices = value?.split('-');
  //   var num1 = prices ? new Intl.NumberFormat('en-IN').format(prices?.[0]) : 0;
  //   var num2 = prices ? new Intl.NumberFormat('en-IN').format(prices?.[1]) : 0;
  //   var num3 = num1 + '-' + num2;

  //   return num3;
  // };
  let data = type === 'custom' ? allCustomEnvelopes : standardValues;
  return (
    <div>
      <CustomModal
        visible={
          type === 'custom'
            ? isCustomModalVisible
            : isStandardTemplateModalVisible
        }
        className={styles.background}
        title={'Choose a DoA based envelope'}
        onCancel={
          type === 'custom' ? handleCustomCancel : handleStandardtemplateCancel
        }
        width={'80%'}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div>
            <SearchBar
              justify="start"
              align="middle"
              className="mt-1"
              gutter={32}
              inputProps={{
                placeholder: 'Search'
              }}
              filters={true}
              statusMenu={statusMenu}
            />
          </div>
          {data?.length ? (
            <Row className="mt-4" gutter={[16, 16]}>
              <>
                {data?.map((item, index) => (
                  <Col key={index} span={8}>
                    <Card className={`${styles.template_card}`} hoverable>
                      <div className={`font-16 mb-2 ${styles.card_title}`}>
                        {item.title}
                      </div>
                      <div className=" mb-3 font-12">
                        <span className="">
                          <FileOutlined />
                          {'\u00a0'}{' '}
                          {item.total_documents_count > 1
                            ? `${item.total_documents_count} formats`
                            : `${item.total_documents_count} format`}
                        </span>
                        <span className="" style={{ marginLeft: '12px' }}>
                          <UserGroup />
                          {'\u00a0'}{' '}
                          {item.total_approvers_count > 1
                            ? `${item.total_approvers_count} approvers`
                            : `${item.total_approvers_count} approver`}
                        </span>
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          Price range
                        </div>
                        {item.min_price === '999' ? (
                          <div className={`font-12`}>Any value</div>
                        ) : (
                          <div className={`font-12`}>
                            {new Intl.NumberFormat('en-IN').format(
                              item.min_price
                            )}{' '}
                            -{' '}
                            {new Intl.NumberFormat('en-IN').format(
                              item.max_price
                            )}
                            {/* {item.price_bracket !== null
                            ? `${item.price_bracket}`
                            : 'Not Applicable'} */}
                          </div>
                        )}
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          Condition
                        </div>
                        <div className={`font-12`}>
                          {item.condition_text ? (
                            <Tooltip title={item.condition_text}>
                              {item.condition_text.slice(0, 40) + '...'}
                            </Tooltip>
                          ) : (
                            'Not Applicable'
                          )}
                        </div>
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          Created on
                        </div>
                        <div className={`font-12`}>
                          {item.createdAt !== null
                            ? moment(item.createdAt).format('DD MMM YY')
                            : 'Not Applicable'}
                        </div>
                      </div>
                      <div className={` font-12 `}>
                        <div className={` font-12 ${styles.icon_color_last}`}>
                          <span className="pull-right">
                            <CustomButton
                              className={`${styles.use_button}`}
                              onClick={() => handleUse(item)}
                            >
                              Use
                            </CustomButton>
                          </span>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </>
            </Row>
          ) : (
            <>
              {' '}
              <div className="df-jc-ac">
                <span>
                  <Image
                    className={`mb-2 ${styles.empty_image}`}
                    alt="survey_empty_state"
                    src={emptyState}
                    preview={false}
                  />
                </span>
              </div>
              <div className={`mb-5 ${styles.empty_state_text}`}>
                <Alert
                  description="Looks like the DoA is not configured for the BU, Category, Sub
                    Category and Project you selected. Please create a ticket in
                    Service Desk to get the DoA configured for your selection
                    criteria"
                  type="info"
                  showIcon
                />
              </div>
            </>
          )}
        </Form>
      </CustomModal>

      {showHistoryModal ? (
        <Modal
          title={<b>Approvers Missing</b>}
          visible={showHistoryModal}
          closable
          onCancel={() => setShowHistoryModal(false)}
          footer={false}
        >
          <Alert
            description="Users are not assigned for one or more approvers in this DoA workflow. Please connect with the support team at nfa@tataprojects.com"
            type="warning"
            icon={<ExclamationCircleOutlined />}
            showIcon
            className="mb-4"
          />
          <Timeline>
            {approverData?.map((item, index) => {
              return (
                <Timeline.Item
                  key={index}
                  color={item.name !== null ? 'green' : 'red'}
                >
                  <div>
                    {' '}
                    {item.name !== null ? (
                      item.name
                    ) : (
                      <span>
                        {item.designation}-({item.approver_role})
                      </span>
                    )}
                  </div>
                  <div style={{ fontSize: '11px' }}>
                    {' '}
                    {item.email !== null ? item.email : 'Not assigned yet'}
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
          <Row className="df-jc-ac">
            <Col>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://login.live.com/"
              >
                <Button type="primary">Contact Support</Button>
              </a>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </div>
  );
};

export default AddCustomModal;
