import apiClient from 'utilities/apiClient';

export const favenvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};

export const deleteenvelop = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};
