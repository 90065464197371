const Templates = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0868 9.50178L19.2888 10.2228C19.3629 10.2672 19.4243 10.33 19.4669 10.4052C19.5095 10.4804 19.5319 10.5654 19.5319 10.6518C19.5319 10.7382 19.5095 10.8232 19.4669 10.8983C19.4243 10.9735 19.3629 11.0364 19.2888 11.0808L10.0038 16.6518L0.718761 11.0808C0.644607 11.0364 0.583227 10.9735 0.540607 10.8983C0.497988 10.8232 0.475586 10.7382 0.475586 10.6518C0.475586 10.5654 0.497988 10.4804 0.540607 10.4052C0.583227 10.33 0.644607 10.2672 0.718761 10.2228L1.92076 9.50178L10.0038 14.3518L18.0868 9.50178ZM18.0868 14.2018L19.2888 14.9228C19.3629 14.9672 19.4243 15.03 19.4669 15.1052C19.5095 15.1804 19.5319 15.2654 19.5319 15.3518C19.5319 15.4382 19.5095 15.5232 19.4669 15.5983C19.4243 15.6735 19.3629 15.7364 19.2888 15.7808L10.5188 21.0428C10.3632 21.1362 10.1852 21.1856 10.0038 21.1856C9.82232 21.1856 9.64429 21.1362 9.48876 21.0428L0.718761 15.7808C0.644607 15.7364 0.583227 15.6735 0.540607 15.5983C0.497988 15.5232 0.475586 15.4382 0.475586 15.3518C0.475586 15.2654 0.497988 15.1804 0.540607 15.1052C0.583227 15.03 0.644607 14.9672 0.718761 14.9228L1.92076 14.2018L10.0038 19.0518L18.0868 14.2018ZM10.5178 0.310779L19.2888 5.57278C19.3629 5.61717 19.4243 5.68003 19.4669 5.75521C19.5095 5.8304 19.5319 5.91535 19.5319 6.00178C19.5319 6.0882 19.5095 6.17316 19.4669 6.24834C19.4243 6.32353 19.3629 6.38639 19.2888 6.43078L10.0038 12.0018L0.718761 6.43078C0.644607 6.38639 0.583227 6.32353 0.540607 6.24834C0.497988 6.17316 0.475586 6.0882 0.475586 6.00178C0.475586 5.91535 0.497988 5.8304 0.540607 5.75521C0.583227 5.68003 0.644607 5.61717 0.718761 5.57278L9.48876 0.310779C9.64429 0.217335 9.82232 0.167969 10.0038 0.167969C10.1852 0.167969 10.3632 0.217335 10.5188 0.310779H10.5178Z"
        fill="rgb(255 255 255 / 85%)"
      />
    </svg>
  );
};
export default Templates;
