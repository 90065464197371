import Layout from '@/common/Layout';
import React from 'react';
import AdminManagePage from '@/features/admin-manage/components';

const Approver = () => {
  return (
    <>
      <Layout sider={true} tracker={true}>
        <AdminManagePage />
      </Layout>
    </>
  );
};

export default Approver;
