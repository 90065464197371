import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { review_Actions } from './api';

const initialState = {
  all_file_data: {},
  allFileLoading: false
};

const actions = {
  GET_FILE_BY_ID: 'getfilebyid/GET_FILE_BY_ID'
};

export const getfilebyid = createAsyncThunk(
  actions.GET_FILE_BY_ID,
  async (payload) => {
    const response = await review_Actions(
      `all-action-list?id=${payload}`,
      payload
    );
    return response;
  }
);

export const getReviewsSlice = createSlice({
  name: 'reviewActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getfilebyid.pending, (state) => {
        state.allFileLoading = true;
      })
      .addCase(getfilebyid.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_file_data = data;
        state.allFileLoading = false;
      })
      .addCase(getfilebyid.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.allFileLoading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = getReviewsSlice.actions; */
/* export const { resetTrackers } = getReviewsSlice.actions; */

export default getReviewsSlice.reducer;
