import LocalStorage from './localStorage';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('NFA_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const blobToFile = (blob, fileName) => {
  blob.lastModified = new Date().getTime();
  blob.name = fileName;

  return blob;
};

export const removeDuplicatesFromArray = (arr, key) => {
  const uniqueArr = [];

  const unique = arr.filter((item) => {
    const isDuplicate = uniqueArr.includes(item[`${key}`]);

    if (!isDuplicate) {
      uniqueArr.push(item[`${key}`]);

      return true;
    }

    return false;
  });

  return unique;
};

export function numFormating(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(0) + 'Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + 'Lac';
  } else if (val >= 1000) {
    val = Math.abs(val)?.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });
  }
  return val;
}
