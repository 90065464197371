import React, { useEffect, useState } from 'react';
import ReviewActionsPage from './components/viewFile';
import { useDispatch, useSelector } from 'react-redux';
// import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  getfilebyid,
  // getOtp,
  update_envelope
  // getRejectedApprovers
} from './redux/slice';
// import { getLoggedInUser } from 'utilities/helpers';
import { history } from 'app/history';
// import { getAllApprovers } from '../initiate-envelope/redux/slice';

function ReviewActions() {
  const { all_file_data, otp, rejected_approvers, allFileLoading } =
    useSelector((state) => state.reviewActions);
  const { allApprovers } = useSelector((state) => state.approvers);

  const [modalVisible, setModalVisible] = useState('');
  const [fileList, setFileList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  // const user = getLoggedInUser();

  useEffect(() => {
    handleGetfileById();
    // handleGetAllApprovers();
  }, []);

  // const handleGetAllApprovers = () => {
  //   dispatch(getAllApprovers());
  // };

  const handleGetfileById = () => {
    const payload = { id: params?.id || searchParams.get('id') };
    dispatch(getfilebyid(payload));
  };

  const handleSetReject = (type) => {
    // let payload = {
    //   envelope_id: params?.id || searchParams.get('id')
    // };
    // dispatch(getRejectedApprovers(payload));
    setModalVisible(type);
  };

  const sentotp = () => {
    // const paylaod = {
    //   email: user.user_details.email,
    //   envelope_id: params?.id || searchParams.get('id')
    // };
    // dispatch(getOtp(paylaod));
    setModalVisible('approved');
  };

  const onFinish = (values) => {
    let formData = new FormData();
    // values['email'] = user.user_details.email;
    // values['env_id'] = params.id;
    // values['status'] =
    //   modalVisible === 'approved'
    //     ? 'Accepted'
    //     : modalVisible === 'reject'
    //     ? 'Rejected'
    //     : modalVisible === 'forward'
    //     ? 'Forwarded'
    //     : modalVisible === 'forward-comments'
    //     ? 'Forwarded-envelope-reviewer'
    //     : modalVisible === 'rejected-comments'
    //     ? 'Rejected-envelope-reviewer'
    //     : 'Hold';

    formData.append('comments', values.comments);
    modalVisible === 'approved' ? formData.append('sign', values.sign) : null;
    formData.append('env_id', searchParams.get('id'));
    formData.append('hashkey', searchParams.get('hashkey'));
    formData.append(
      'status',
      modalVisible === 'approved'
        ? 'Accepted'
        : modalVisible === 'reject'
        ? 'Rejected'
        : ''
    );
    for (let i = 0; i < fileList.length; i++) {
      formData.append(`file`, fileList[i].originFileObj);
    }

    // const payload = {
    //   status:
    //     modalVisible === 'approved'
    //       ? 'Accepted'
    //       : modalVisible === 'reject'
    //       ? 'Rejected'
    //       : '',
    //   comments: values.comments,
    //   env_id: searchParams.get('id'),
    //   sign: values.sign,
    //   hashkey: searchParams.get('hashkey')
    // };
    dispatch(update_envelope(formData)).then((response) => {
      if (response.payload.success) {
        // message.success(response?.payload?.message);
        history.push(`/success`);
      }
    });
    setModalVisible('');
    setFileList([]);
  };

  return (
    <>
      <ReviewActionsPage
        allFileLoading={allFileLoading}
        fileList={fileList}
        setFileList={setFileList}
        allApprovers={allApprovers}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        all_file_data={all_file_data}
        sentotp={sentotp}
        otp={otp}
        onFinish={onFinish}
        handleSetReject={handleSetReject}
        rejected_approvers={rejected_approvers}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    </>
  );
}

export default ReviewActions;
