import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row, Tag, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSbus, getAllbus, getBuById } from '../../redux/slice';
import Pencil from '@/features/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';

const BU = () => {
  const { all_BUs, buById, bu_pagination, all_SBUs } = useSelector(
    (state) => state.settings
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const [sbuSearch, setSbuSearch] = useState('');

  const handleGetAllSbus = () => {
    let payload = sbuSearch !== '' ? { search: sbuSearch } : {};
    dispatch(getAllSbus(payload));
  };

  const handleSbuSearchChange = (e) => {
    setSbuSearch(e);
  };

  useEffect(() => {
    handleGetAllSbus();
  }, [sbuSearch]);

  const showModal = () => {
    setSbuSearch('');
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (id) => {
    const payload = { id: id };
    dispatch(getBuById(payload));

    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetAllApprovers = () => {
    let payload =
      search !== '' ? { search: search } : { search: search, page: page };
    //dispatch(getAllSbus(payload));
    dispatch(getAllbus(payload));
  };

  useEffect(() => {
    handleGetAllApprovers();
  }, [search, page]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const data = all_BUs?.map((item) => {
    const { id, name, is_active, sbu_master } = item;
    return {
      ...item,
      status: {
        is_active
      },
      approvers: {
        name
      },

      id: { id },
      sbu_name: sbu_master?.sbu_name
    };
  });

  const columns = [
    {
      title: 'BU Name',
      dataIndex: 'approvers',
      key: 'approvers',
      render: (approvers) => <>{approvers?.name}</>
    },
    {
      title: 'SBU Name',
      dataIndex: 'sbu_name',
      key: 'sbu_name',
      render: (sbu_name) => <>{sbu_name}</>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status?.is_active === true ? 'green' : 'grey'}>
          {status?.is_active === true ? 'Active' : 'Inactive'}
        </Tag>
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Tooltip title="Edit">
          <span
            className="cursor-pointer"
            onClick={() => {
              showEditModal(id.id);
            }}
          >
            <Pencil />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/bu"
              >
                Configure BUs
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Add BU
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">BUs</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by BU name'
            }}
          />
        </Col>
      </Row>
      {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any flows yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add BU
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          /*  rowSelection={true} */
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: bu_pagination?.total_records,
            showSizeChanger: false
          }}
          dataSource={data}
          columns={columns}
        />
      )}
      {isModalOpen ? (
        <AddModal
          handleSbuSearchChange={handleSbuSearchChange}
          all_SBUs={all_SBUs}
          handleGetAllApprovers={handleGetAllApprovers}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          setSbuSearch={setSbuSearch}
          all_SBUs={all_SBUs}
          handleSbuSearchChange={handleSbuSearchChange}
          handleGetAllApprovers={handleGetAllApprovers}
          id={id}
          setId={setId}
          buById={buById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default BU;
