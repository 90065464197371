import React, { useState } from 'react';
import { Alert, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { ExclamationCircleFilled } from '@ant-design/icons';

const AdhocModal = ({ handleCancel, isModalVisible, onFinish, loading }) => {
  const [form] = Form.useForm();
  const [isChecked, setIsChecked] = useState(false);
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };
  return (
    <CustomModal
      footer={null}
      onCancel={handleCancel}
      visible={isModalVisible}
      title="Initiate ad-hoc envelope"
    >
      <Alert
        icon={<ExclamationCircleFilled />}
        showIcon
        message={<b>Caution</b>}
        className="mb-3"
        type="warning"
        description={
          <div
            style={{ fontWeight: 400, fontSize: '12px', marginLeft: '-40px' }}
          >
            Adhoc envelopes should only be utilized in the absence of a Standard
            DOA. Before proceeding with any ad-hoc DOA, ensure that you have
            thoroughly checked the Standard DOA. Bypassing the Standard DOA
            without proper justification may result in procedural errors and
            potential consequences.
          </div>
        }
      />
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        <FormItem
          label="Envelope Name"
          name="envelope_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem>

        <FormItem
          label="Description"
          name="description"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Input.TextArea rows={5} allowClear placeholder="Enter description" />
        </FormItem>
        <FormItem
          label=""
          name="checkbox"
          rules={[
            {
              required: false
            }
          ]}
        >
          <Row gutter={[16, 0]}>
            <Col span={1}>
              <Checkbox onChange={toggleCheckbox} allowClear className="mr-2" />{' '}
            </Col>
            <Col span={23}>
              <span style={{ fontWeight: 'lighter' }}>
                I have verified the applicability of the Standard DOA for this
                envelope and would like to proceed with the creation of an
                ad-hoc envelope.
              </span>
            </Col>
          </Row>
        </FormItem>

        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              disabled={!isChecked}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Next
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};

export default AdhocModal;
