import Header from '@/common/Content/header';
import SearchBar from '@/common/Content/searchBar';
import CustomButton from '@/common/CustomButton';
import { ClockCircleOutlined } from '@ant-design/icons';

import { Badge, Menu, Popover, Table } from 'antd';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getActionsRequired } from '../../redux/slice';
import styles from './index.module.less';

const ActionsRequiredPage = ({ selectedStatus, setSelectedStatus }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'icons',
      key: 'id',
      render: () => {
        return (
          <>
            <div className={`${styles.table_icon}`}>
              {<ClockCircleOutlined />}
            </div>
          </>
        );
      }
    },
    {
      title: 'Title',
      dataIndex: 'subject',
      key: 'id',
      width: '40%',
      render: (subject) => (
        <>
          <div className="mb-1">
            <b className="font-14 ">{subject.title}</b>
          </div>
        </>
      )
    },
    {
      title: 'Accepters',
      dataIndex: 'description',
      key: 'id',
      width: '60%',
      render: (description, record) => (
        <>
          <div className="mb-1 mr-5">
            <div>
              To:{' '}
              {description?.accepters?.length > 2
                ? description?.accepters
                    ?.slice(0, 2)
                    .map((item, index) => (index ? ', ' : '') + item.name)
                : description?.accepters?.map(
                    (item, index) => (index ? ', ' : '') + item.name
                  )}
              {'\u00a0'}
              {record?.project_name ? `${' || '}${record?.project_name}` : ''}
              <Popover
                content={
                  <>
                    <h2 className="sfprotext-bold">Approvers</h2>
                    {description?.accepters?.map((item, index) => (
                      <p key={index}>{item.name}</p>
                    ))}
                  </>
                }
                placement="rightTop"
              >
                {description?.accepters?.length > 2 &&
                description?.accepters?.length !== null ? (
                  <Badge
                    className={`site-badge-count-109 ${styles.badge}`}
                    count={`+ ${description?.accepters?.length - 2}`}
                  />
                ) : (
                  ''
                )}
              </Popover>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'id',
      width: '20%',
      render: (actions) => {
        return (
          <>
            <Link to={'review-actions/' + `${actions.id}`}>
              <CustomButton type="primary" className={`${styles.use_button}`}>
                {'Review'}
              </CustomButton>
            </Link>
          </>
        );
      }
    }
  ];
  const [search, setSearch] = useState('');
  const { all_actions } = useSelector((state) => state.actionsList);

  const data = all_actions?.map((item) => {
    const { accepters, name, id, created_by, title } = item;
    return {
      ...item,
      subject: {
        id,
        name,
        created_by,
        title
      },
      description: {
        id,
        accepters: accepters
      },
      actions: {
        id
      }
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetStats();
  }, [search]);
  const handleGetStats = () => {
    let payload = search !== '' ? { search: search } : {};
    dispatch(getActionsRequired(payload));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Open', status: 'open' },
    { label: 'Deleted', status: 'deleted' },
    { label: 'Assigned', status: 'Assigned' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className={`mb-4 ${styles.MRS_container}`}>
        <Header justify="space-between" align="middle" buttons={[]} />
        <div>
          <SearchBar
            justify="start"
            align="middle"
            gutter={32}
            inputProps={{
              placeholder: 'Search by envelope or project'
            }}
            filters={false}
            statusMenu={statusMenu}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div>
        <Table
          rowKey={(obj) => obj.id}
          className={`${styles.custom_table}`}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default ActionsRequiredPage;
