import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './components/LoginPage';
import { login, verify_secret } from './redux/slice';

const Login = () => {
  const { loading } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const secret = new URLSearchParams(location.search).get('secret');
  const id = new URLSearchParams(location.search).get('secret_id');

  useEffect(() => {
    handleGetStats();
  }, []);

  const handleGetStats = () => {
    let payload = { secret_id: id, secret: secret };
    dispatch(verify_secret(payload));
  };
  const onSubmit = (payload) => {
    dispatch(login(payload));
  };

  return (
    <>
      <LoginPage loading={loading} onSubmit={onSubmit} />
    </>
  );
};

export default Login;
