import { Result } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../CustomButton';
import { getLoggedInUser } from 'utilities/helpers';

const PageNotFound = () => {
  const user = getLoggedInUser();
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link
            to={
              user?.user_details?.role === 'admin'
                ? '/admin-dashboard'
                : '/dashboard'
            }
          >
            <CustomButton type="primary">Back Home</CustomButton>
          </Link>
        }
      />
    </>
  );
};

export default PageNotFound;
