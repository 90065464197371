import apiClient from '../../../utilities/apiClient';

export const review_Actions = (url) => {
  return apiClient.get(
    `${apiClient.Urls.all_actions_required}/${url}`,
    '',
    true
  );
};
export const get_otp = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.otp}/${url}`, payload, true);
};

export const update_env = (payload) => {
  const email = payload.get('email');
  const env_id = payload.get('env_id');
  return apiClient.patch(
    `${apiClient.Urls.otp}/update?envelope_id=${env_id}&email=${email}`,
    payload,
    true,
    'file'
  );
};

export const cancelEnvelope = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.approvers}/${url}`, payload, true);
};

export const get_rejected_approvers = (url) => {
  return apiClient.get(`${apiClient.Urls.otp}/${url}`, '', true);
};
