import React from 'react';
import { Image, Form, Input, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less';
import tataLogo from 'features/login/images/tata_logo.svg';
import FormItem from 'common/FormItem/index';
import CustomButton from 'common/CustomButton/index';
import AuthWrapper from 'common/AuthWrapper/index';
import { LeftOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ForgotPasswordPage = ({ loading, onSubmit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const { email } = values;

    let payload = {
      email
    };

    onSubmit(payload);
  };

  return (
    <AuthWrapper>
      <div className={`df-jc-ac flex-column ${styles.right_outer_container}`}>
        <div className={styles.right_image}>
          <Image alt="tata_logo" src={tataLogo} preview={false} />
        </div>
        <div className="text-center mt-4">
          <Text className="d-block sfprotext-medium font-22 mb-1">{`Forgot your password`}</Text>
          <Text className="d-block">{`Please enter the email address you’d like your password reset information sent to`}</Text>
        </div>
        <Form
          form={form}
          layout="vertical"
          className="mt-4 w-100"
          onFinish={onFinish}
        >
          <FormItem
            label="Enter email address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <Input placeholder="Enter ID" />
          </FormItem>
          <FormItem>
            <CustomButton
              className={`ant_button ant_button_secondary ${styles.secondary_button}`}
              block
              type="secondary"
              htmlType="submit"
              loading={loading}
            >
              Request reset link
            </CustomButton>
          </FormItem>
          <CustomButton
            block
            font="medium"
            type="link"
            htmlType="button"
            onClick={() => navigate('/login')}
            className="text-center font-12"
            icon={<LeftOutlined className="font-10" />}
          >
            Back to login page
          </CustomButton>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPasswordPage;
