import Layout from '@/common/Layout';
import React from 'react';
import ApprovedReceiptsPage from './components';

const ApprovedReceipts = () => {
  return (
    <>
      <Layout sider={false} tracker={true}>
        <ApprovedReceiptsPage />
      </Layout>
    </>
  );
};

export default ApprovedReceipts;
